import { Component, Inject, OnInit } from '@angular/core';
import { FormGroup, FormControl, FormBuilder, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-search-customer-dlg',
  templateUrl: './search-customer-dlg.component.html',
  styleUrls: ['./search-customer-dlg.component.scss']
})
export class SearchCustomerDlgComponent implements OnInit {
  form: FormGroup;
  error: string = '';

  constructor(
    private fb: FormBuilder,
    private dialogRef: MatDialogRef<SearchCustomerDlgComponent>,
    @Inject(MAT_DIALOG_DATA) keyword: string
  ) {
    this.form = this.fb.group({
      keyword: [keyword, [Validators.required]]
    });
  }

  ngOnInit(): void {
  }

  search() {
    if (this.form.valid) {
      this.dialogRef.close(this.form.value.keyword);
    }
    else {
      this.error = 'Filter required.';
    }
  }

  clear(){
    this.dialogRef.close("");
  }
}
