import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { SelectCompanyDlgComponent } from 'src/app/components/select-company-dlg/select-company-dlg.component';
import { CompanyModel } from 'src/app/models/company';
import { Page } from 'src/app/models/page';
import { getFullName } from 'src/app/models/user';
import { AuthService } from 'src/app/services/auth.service';
import { CompanyService } from 'src/app/services/company.service';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-main-layout',
  templateUrl: './main-layout.component.html',
  styleUrls: ['./main-layout.component.scss']
})
export class MainLayoutComponent implements OnInit {
  dlgConfig:MatDialogConfig;
  bLoadedCompanyList: boolean = false;

  constructor(
    private userService: UserService,
    private companyService: CompanyService,
    private authService: AuthService,
    private dialog: MatDialog,
    private router: Router
  ) {
    this.dlgConfig = new MatDialogConfig();
    this.dlgConfig.panelClass='popup-dlg-panel';
    this.dlgConfig.disableClose = false;
    this.dlgConfig.autoFocus = true;
    this.dlgConfig.width="500px";
  }

  ngOnInit(): void {
    this.companyService.getCompanyList(
      undefined,
      (data:Page<CompanyModel>)=>{
        this.companyService.companyList=data.content;
        this.bLoadedCompanyList = true;
        if(!this.companyService.companyData && this.companyService.companyList.length > 0){
          this.companyService.setMyCompany(this.companyService.companyList[0]);
        }
      }
    )
  }

  openSelectCompanyDlg(){
    if(!this.bLoadedCompanyList){
      return;
    }
    const companySelectData:any={
      companyList: this.companyService.companyList,
      selected: this.companyService.companyData
    };

    this.dlgConfig.data = companySelectData;
    this.dialog.open(SelectCompanyDlgComponent, this.dlgConfig)
    .afterClosed().subscribe((data:number)=>{ // data: id of selected company
      console.log(data);
      if(data){
        const len=this.companyService.companyList.length;
        
        for(let i=0; i<len; i++){
          if(this.companyService.companyList[i].companyId==data){
            this.companyService.setMyCompany(this.companyService.companyList[i]);
            return;
          }
        }
      }
    });
  }

  signOut(){
    console.log("log out");
    this.authService.deleteToken();
    this.userService.removeUserData();
    this.companyService.unsetCompanyData();
    this.router.navigate(['/auth']);
  }

  getUserName(){
    const fullname = getFullName(this.userService.userData);
    return fullname? fullname : "unknown";
  }

  getCompanyName(){
    if(this.companyService.companyData)
      return this.companyService.companyData.name;
    return "MyCompany";
  }
}
