<h2 mat-dialog-title>{{title}}</h2>
<form [formGroup]="form" (ngSubmit)="save()">
    <mat-dialog-content>
        <mat-form-field class="w-100">
            <mat-label>Note</mat-label>
            <textarea 
                matInput 
                class="w-100"
                placeholder="Input your note..." 
                minlength="5"
                maxlength="500"
                required
                formControlName="note"></textarea>
            <mat-error *ngIf="noteInput.invalid">{{noteInputError}}</mat-error>
        </mat-form-field>
        <input type="hidden" formControlName="category" />
        <input type="hidden" formControlName="clientId" />
        <input type="hidden" formControlName="note" />
        <input type="hidden" formControlName="noteId" />
        <input type="hidden" formControlName="referenceId" />
        <input type="hidden" formControlName="status" />
        <input type="hidden" formControlName="createdDate" />
        <input type="hidden" formControlName="modifiedDate" />
        <input type="hidden" formControlName="createdBy" />
        <input type="hidden" formControlName="modifiedBy" />
        <p>Max length 500 character</p>
    </mat-dialog-content>

    <mat-dialog-actions align="end">
        <button type="button" mat-raised-button mat-dialog-close color="warn">Close</button> 
        <button type="submit" mat-raised-button color="accent">Save</button>
    </mat-dialog-actions>
</form>