<h2 mat-dialog-title>{{title}}</h2>
<form [formGroup]="form" (ngSubmit)="save()">
    <mat-dialog-content>
        <mat-form-field appearance="standard" class="w-100">
            <mat-label>Location Code</mat-label>
            <input matInput
                    type="text"
                    placeholder="Location Code"
                    required
                formControlName="code">
            <mat-error *ngIf="codeInput.invalid">{{codeInputError}}</mat-error>
        </mat-form-field>
        <mat-form-field appearance="standard" class="w-100">
            <mat-label>Location Name</mat-label>
            <input matInput
                    type="text"
                    placeholder="Location Name..."
                    required
                formControlName="name">
            <mat-error *ngIf="nameInput.invalid">{{nameInputError}}</mat-error>
        </mat-form-field>
        <mat-form-field appearance="standard" class="w-100">
            <mat-label>Description</mat-label>
            <input matInput
                    type="text"
                    placeholder="Description"                    
                formControlName="description">
        </mat-form-field>
        <mat-form-field appearance="standard" class="w-100">
            <mat-label>Position</mat-label>
            <input matInput
                    type="text"
                    placeholder="Position"                    
                formControlName="position">
        </mat-form-field>        
        <mat-form-field appearance="standard" class="w-100">
            <mat-label>Latitude</mat-label>
            <input matInput
                    type="number"
                    placeholder="Latitude"                    
                formControlName="latitude">
        </mat-form-field>
        <mat-form-field appearance="standard" class="w-100">
            <mat-label>Longtitude</mat-label>
            <input matInput
                    type="number"
                    placeholder="Longtitude"                    
                formControlName="longtitude">
        </mat-form-field>
        <input type="hidden" formControlName="clientId" />
        <input type="hidden" formControlName="status" />
        <input type="hidden" formControlName="locationId" />
        <input type="hidden" formControlName="createdDate" />
        <input type="hidden" formControlName="modifiedDate" />
        <input type="hidden" formControlName="createdBy" />
        <input type="hidden" formControlName="modifiedBy" />
    </mat-dialog-content>
    <mat-divider></mat-divider>
    <mat-dialog-actions align="end">
        <button type="button" mat-raised-button mat-dialog-close color="warn">Close</button> 
        <button type="submit" mat-raised-button color="accent">Save</button>
    </mat-dialog-actions>
</form>