export interface UserModel {
  apiKey:	string;
  apiSecret:	string;
  clientId:	number;
  countryCode:	string;
  email:	string;
  firstname:	string;
  language:	string;
  lastname:	string;
  password?:	string;
  phoneNo:	string;
  role:	string;
  status:	string;
  timezone:	string;
  timezoneId:	number;
  userId:	number;
  username:	string;
  utcDstOffset:	string;
  utcOffset:	string;
  createdDate:	string;
  modifiedDate:	string;
  createdBy:	number;
  modifiedBy:	number;
}

export function getFullName(user: UserModel|null|undefined){
  if(user)
    return `${user.firstname} ${user.lastname}`;
  else
    return '';
}