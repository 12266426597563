import { Component, Inject, OnInit } from '@angular/core';
import { PaymentModel } from 'src/app/models/payment';
import { PaymentMethodModel } from 'src/app/models/payment.method';
import {MAT_MOMENT_DATE_FORMATS, MomentDateAdapter} from '@angular/material-moment-adapter';
import {DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE} from '@angular/material/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import * as moment from 'moment';
import { CompanyModel } from 'src/app/models/company';
import { PaymentService } from 'src/app/services/payment.service';
import { ToastService } from 'src/app/services/toast.service';
import { Constants } from 'src/app/config/constant';


export const MY_FORMATS = {
  parse: {
      dateInput: 'LL'
  },
  display: {
      dateInput: 'MM/DD/YYYY',
      monthYearLabel: 'YYYY',
      dateA11yLabel: 'LL',
      monthYearA11yLabel: 'YYYY'
  }
};

@Component({
  selector: 'app-edit-payment-dlg',
  templateUrl: './edit-payment-dlg.component.html',
  styleUrls: ['./edit-payment-dlg.component.scss'],
  providers: [
    {provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE]},
    {provide: MAT_DATE_FORMATS, useValue: MY_FORMATS},
  ],
})
export class EditPaymentDlgComponent implements OnInit {
  form: FormGroup;
  title:string;
  bCreateMode:boolean;
  paymentMethods:PaymentMethodModel[];
  paymentData: PaymentModel;
  error:string|null =null;

  constructor(
    private router: Router,
    private paymentService: PaymentService, 
    private dialogRef: MatDialogRef<EditPaymentDlgComponent>,
    private fb: FormBuilder,
    private toastService: ToastService,
    @Inject(MAT_DIALOG_DATA) data:any
  ) {
    this.paymentData={...data.payment};
    this.paymentMethods=data.method;

    const paymentDate = moment(this.paymentData.paymentDate, 'YYYY-MM-DD');
    const paymentMethod = (this.paymentData.paymentMethodId>0)?this.paymentData.paymentMethodId:'';
    this.form = this.fb.group({
      paymentDate: [paymentDate, [Validators.required]],
      paymentMethodId: [paymentMethod, [Validators.required]],
      paymentAmount: [this.paymentData.paymentAmount, [Validators.required, Validators.min(1)]],
      comment: [this.paymentData.comment],
    });

    if(this.paymentData.paymentId ===0){
      this.title='New Payment';
      this.bCreateMode=true;
    }
    else{
      this.bCreateMode=false;
      this.title='Edit Payment';
    }
  }

  ngOnInit(): void {
  }

  save(){
    if (this.form.valid) {
      const editedValue=this.form.value;
      const paymentDate = moment(editedValue.paymentDate, 'MM/DD/YYYY');
      this.paymentData.paymentDate = paymentDate.format('YYYY-MM-DD');
      this.paymentData.paymentMethodId=editedValue.paymentMethodId;
      this.paymentData.paymentAmount = editedValue.paymentAmount;
      this.paymentData.comment= editedValue.comment;

      this.paymentService.savePayment(
        this.paymentData,
        this.onSave,
        this.onNotSave
      );
    }
  }

  close() {
    this.dialogRef.close();
  }

  onSave=(pro:CompanyModel)=>{
    this.toastService.showSuccess(Constants.message.successSaved);
    this.dialogRef.close(pro);
  }

  onNotSave=(error: any) => {
    this.toastService.showResponseError(error);
    if(error.status == 401){
      this.router.navigate(['/auth/sign-in']);
    }
  }
  
  get paymentDateInput(): any {return this.form.get('paymentDate'); }
  get paymentDateInputError(): string | void {    
    if (this.paymentDateInput.hasError('required')) { return Constants.message.requiredField; }
  }
  get paymentMethodIdInput(): any {return this.form.get('paymentMethodId'); }
  get paymentMethodIdInputError(): string | void {    
    if (this.paymentMethodIdInput.hasError('required')) { return Constants.message.requiredField; }
  }
  get paymentAmountInput(): any {return this.form.get('paymentAmount'); }
  get paymentAmountInputError(): string | void {    
    if (this.paymentAmountInput.hasError('required')) { return Constants.message.requiredField; }
    if (this.paymentAmountInput.hasError('min')) { return Constants.message.invalidMinValue1; }
  }
}
