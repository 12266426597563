import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CustomerModel } from 'src/app/models/customer';
import { EventModel } from 'src/app/models/event';

@Component({
  selector: 'app-delete-event-dlg',
  templateUrl: './delete-event-dlg.component.html',
  styleUrls: ['./delete-event-dlg.component.scss']
})
export class DeleteEventDlgComponent implements OnInit {
  customer:CustomerModel;
  event:EventModel;

  constructor(
    private dialogRef: MatDialogRef<DeleteEventDlgComponent>,
    @Inject(MAT_DIALOG_DATA) data:any
  ) {
    this.customer = data.customer;
    this.event = data.event;
  }

  ngOnInit(): void {
  }

  delete(){
    this.dialogRef.close(true);
  }
}
