import { UserService } from 'src/app/services/user.service';
import { Component, Inject, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { UserModel } from 'src/app/models/user';
// import { ProfessionalService } from 'src/app/services/professional.service';
//import * as moment from 'moment';
import * as moment from 'moment-timezone';
import { ToastService } from 'src/app/services/toast.service';
import { Constants } from 'src/app/config/constant';

@Component({
  selector: 'app-edit-user-dlg',
  templateUrl: './edit-user-dlg.component.html',
  styleUrls: ['./edit-user-dlg.component.scss'],
})
export class EditUserDlgComponent implements OnInit {
  tzNames: string[];
  form: FormGroup;
  title: string;
  bCreateMode: boolean;

  constructor(
    private router: Router,
    // private proService: ProfessionalService,
    private userService: UserService,
    private dialogRef: MatDialogRef<EditUserDlgComponent>,
    private fb: FormBuilder,
    private toastService: ToastService,
    @Inject(MAT_DIALOG_DATA) data: UserModel
  ) {
    this.tzNames = moment.tz.names();
    this.form = this.fb.group({
      // I commented out this two lines, and the error gone.
      // apiKey: [data.apiKey],
      // apiSecret: [data.apiSecret],
      clientId: [data.clientId],
      countryCode: [data.countryCode],
      email: [data.email, [Validators.required, Validators.email]],
      phoneNo: [data.phoneNo],
      status: [data.status],
      userId: [data.userId],
      firstname: [data.firstname, [Validators.required]],
      lastname: [data.lastname, [Validators.required]],
      username: [data.username, [Validators.required]],
      password: [data.password],
      role: [data.role],
      language: [data.language],
      timezone: [data.timezone],
      timezoneId: [data.timezoneId],
      utcDstOffset: [data.utcDstOffset],
      utcOffset: [data.utcOffset],
      createdDate: [data.createdDate],
      modifiedDate: [data.modifiedDate],
      createdBy: [data.createdBy],
      modifiedBy: [data.modifiedBy],
    });
    if (data.userId === 0) {
      this.title = 'Add User';
      this.bCreateMode = true;
    } else {
      this.bCreateMode = false;
      this.title = 'Edit User';
    }
  }

  ngOnInit(): void {}

  save() {
    if (this.form.valid) {
      console.log('save user', this.form);

      this.userService.saveUser(this.form.value, this.onSave, this.onNotSave);
      // this.proService.saveProfessional(
      //   this.form.value,
      //   this.onSave,
      //   this.onNotSave
      // );
    }
  }

  close() {
    this.dialogRef.close();
  }

  onSave = (user: UserModel) => {
    this.toastService.showSuccess(Constants.message.successSaved);
    this.dialogRef.close(user);
  };

  onNotSave = (error: any) => {
    this.toastService.showResponseError(error);
    if (error.status == 401) {
      this.router.navigate(['/auth/sign-in']);
    }
  };

  get emailInput(): any {
    return this.form.get('email');
  }
  get emailInputError(): string | void {
    if (this.emailInput.hasError('email')) {
      return Constants.message.invalidEmail;
    }
    if (this.emailInput.hasError('required')) {
      return Constants.message.requiredField;
    }
  }

  get firstnameInput(): any {
    return this.form.get('firstname');
  }
  get firstnameInputError(): string | void {
    if (this.firstnameInput.hasError('required')) {
      return Constants.message.requiredField;
    }
  }

  get lastnameInput(): any {
    return this.form.get('lastname');
  }
  get lastnameInputError(): string | void {
    if (this.lastnameInput.hasError('required')) {
      return Constants.message.requiredField;
    }
  }

  get usernameInput(): any {
    return this.form.get('username');
  }
  get usernameInputError(): string | void {
    if (this.usernameInput.hasError('required')) {
      return Constants.message.requiredField;
    }
  }
}
