import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApiUrls } from '../config/api.endpoints';
import { PaymentModel } from '../models/payment';
import { Page } from '../models/page';
import { AuthService } from './auth.service';
import { QueryParams, createHttpParams } from '../models/query-params';

@Injectable({
  providedIn: 'root'
})
export class PaymentService {
  constructor(
    private http: HttpClient,
    private authService: AuthService
  ) { }

  getPayment(paymentId:number, onSuccess:any, onError?:any){
    
    const httpOptions = {
      headers: new HttpHeaders({
        'x-auth-token': this.authService.token
      }),
    };
    this.http.get<PaymentModel>(ApiUrls.PAYMENTS+'/'+paymentId, httpOptions)
      .subscribe((data)=>{
        if(onSuccess)
          onSuccess(data);
      }, (error:HttpErrorResponse)=>{
        if(onError)
          onError(error.status);
      });
  }
  
  getPayments(
    queryParams?: QueryParams,
    onSuccess?:any, onError?:any
  ){
    
    const httpOptions = {
      params: createHttpParams(queryParams),
      headers: new HttpHeaders({
        'x-auth-token': this.authService.token
      }),
    };

    this.http.get<Page<PaymentModel>>(ApiUrls.PAYMENTS, httpOptions)
      .subscribe((data)=>{
        if(onSuccess)
          onSuccess(data);
      }, (error:HttpErrorResponse)=>{
        if(onError)
          onError(error.status);
      });
  }

  getCustomerPayments(
    customerId:number,
    queryParams?: QueryParams,
    onSuccess?:any, onError?:any
  ){
    
    const httpOptions = {
      params: createHttpParams(queryParams),
      headers: new HttpHeaders({
        'x-auth-token': this.authService.token
      }),
    };

    this.http.get<Page<PaymentModel>>(ApiUrls.PAYMENTS+'/customer/'+customerId, httpOptions)
      .subscribe((data)=>{
        if(onSuccess)
          onSuccess(data);
      }, (error:HttpErrorResponse)=>{
        if(onError)
          onError(error.status);
      });
  }

  getCompanyPayments(
    companyId:number,
    queryParams?: QueryParams,
    onSuccess?:any, onError?:any
  ){
    
    const httpOptions = {
      params: createHttpParams(queryParams),
      headers: new HttpHeaders({
        'x-auth-token': this.authService.token
      }),
    };

    this.http.get<Page<PaymentModel>>(ApiUrls.PAYMENTS+'/company/'+companyId, httpOptions)
      .subscribe((data)=>{
        if(onSuccess)
          onSuccess(data);
      }, (error:HttpErrorResponse)=>{
        if(onError)
          onError(error.status);
      });
  }

  deletePayment(
    paymentId:number, 
    onSuccess?:any, onError?:any){
    const httpOptions = {
      headers: new HttpHeaders({
        'x-auth-token': this.authService.token
      }),
    };

    this.http.delete<void>(ApiUrls.PAYMENTS+'/'+paymentId, httpOptions)
      .subscribe(()=>{
        if(onSuccess)
          onSuccess();
      }, (error:HttpErrorResponse)=>{
        if(onError)
          onError(error);
      });
  }

  undeletePayment(
    paymentId:number, 
    onSuccess?:any, onError?:any){
    const httpOptions = {
      headers: new HttpHeaders({
        'x-auth-token': this.authService.token
      }),
    };

    this.http.get<void>(ApiUrls.PAYMENTS+'/recycle/'+paymentId, httpOptions)
      .subscribe(()=>{
        if(onSuccess)
          onSuccess();
      }, (error:HttpErrorResponse)=>{
        if(onError)
          onError(error);
      });
  }

  savePayment(
    payment:PaymentModel,
    onSuccess?:any, onError?:any
  ){
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'x-auth-token': this.authService.token
      }),
    };

    this.http.post<PaymentModel>(ApiUrls.PAYMENTS, payment, httpOptions)
      .subscribe((data)=>{
        if(onSuccess)
          onSuccess(data);
      }, (error:HttpErrorResponse)=>{
        if(onError)
          onError(error);
      });
  }

  undoDeletePayment(paymentId:number, onSuccess:any, onError?:any){
    
    const httpOptions = {
      headers: new HttpHeaders({
        'x-auth-token': this.authService.token
      }),
    };
    this.http.get<PaymentModel>(ApiUrls.PAYMENTS+'/recycle/'+paymentId, httpOptions)
      .subscribe((data)=>{
        if(onSuccess)
          onSuccess(data);
      }, (error:HttpErrorResponse)=>{
        if(onError)
          onError(error.status);
      });
  }

}
