import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ToastrModule } from 'ngx-toastr';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ShareModule } from './shared/material.module';
import { MainLayoutComponent } from './pages/main-layout/main-layout.component';
import { HeaderComponent } from './components/header/header.component';
import { SelectCompanyDlgComponent } from './components/select-company-dlg/select-company-dlg.component';
import { EidtPasswordDlgComponent } from './components/eidt-password-dlg/eidt-password-dlg.component';
import { EidtAddressDlgComponent } from './components/eidt-address-dlg/eidt-address-dlg.component';
import { ConfirmDlgComponent } from './components/confirm-dlg/confirm-dlg.component';
import { EditNoteDlgComponent } from './components/edit-note-dlg/edit-note-dlg.component';
import { EditProfessionalDlgComponent } from './components/edit-professional-dlg/edit-professional-dlg.component';
import { EditUserDlgComponent } from './components/edit-user-dlg/edit-user-dlg.component';
import { EditUserAccessDlgComponent } from './components/edit-user-access-dlg/edit-user-access-dlg.component';
import { ResetPasswordDlgComponent } from './components/reset-password-dlg/reset-password-dlg.component';
import { EditOfficeDlgComponent } from './components/edit-office-dlg/edit-office-dlg.component';
import { EditServiceDlgComponent } from './components/edit-service-dlg/edit-service-dlg.component';
import { EditTaxCodeDlgComponent } from './components/edit-tax-code-dlg/edit-tax-code-dlg.component';
import { EditPaymentMethodDlgComponent } from './components/edit-payment-method-dlg/edit-payment-method-dlg.component';
import { EditCompanyDlgComponent } from './components/edit-company-dlg/edit-company-dlg.component';
import { EditCustomerDlgComponent } from './components/edit-customer-dlg/edit-customer-dlg.component';
import { EditLocationDlgComponent } from './components/edit-location-dlg/edit-location-dlg.component';
import { ShowEventDlgComponent } from './components/show-event-dlg/show-event-dlg.component';
import { DeleteEventDlgComponent } from './components/delete-event-dlg/delete-event-dlg.component';
import { EditEventDlgComponent } from './components/edit-event-dlg/edit-event-dlg.component';
import { EditInvoiceDlgComponent } from './components/edit-invoice-dlg/edit-invoice-dlg.component';
import { EditPaymentDlgComponent } from './components/edit-payment-dlg/edit-payment-dlg.component';
import { SearchCustomerDlgComponent } from './components/search-customer-dlg/search-customer-dlg.component';
import { CustomToastComponent } from './components/custom-toast/custom-toast.component';

@NgModule({
  declarations: [
    AppComponent,
    MainLayoutComponent,
    HeaderComponent,
    SelectCompanyDlgComponent,
    EidtPasswordDlgComponent,
    EidtAddressDlgComponent,
    ConfirmDlgComponent,
    EditNoteDlgComponent,
    EditProfessionalDlgComponent,
    EditUserDlgComponent,
    EditUserAccessDlgComponent,
    ResetPasswordDlgComponent,
    EditOfficeDlgComponent,
    EditServiceDlgComponent,
    EditTaxCodeDlgComponent,
    EditPaymentMethodDlgComponent,
    EditCompanyDlgComponent,
    EditCustomerDlgComponent,
    EditLocationDlgComponent,
    ShowEventDlgComponent,
    DeleteEventDlgComponent,
    EditEventDlgComponent,
    EditInvoiceDlgComponent,
    EditPaymentDlgComponent,
    SearchCustomerDlgComponent,
    CustomToastComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    ShareModule,
    ToastrModule.forRoot(),
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
