<h2 mat-dialog-title>User Access of {{username}}</h2>
<mat-divider></mat-divider>
<mat-dialog-content>
  <p *ngIf="proList.length>0">Select Professionals who user should have access to.</p>
  <p *ngIf="bLoading">Loading...</p>
  <span>
        <ul>
          <li *ngFor="let pro of proList">
            <mat-checkbox [(ngModel)]="pro.access"
                          color="primary">
              <label>{{pro.professional.firstname}} {{pro.professional.lastname}}</label>
            </mat-checkbox>
          </li>
        </ul>
      </span>
</mat-dialog-content>
<mat-divider></mat-divider>
<mat-dialog-actions align="end">
    <button type="button" mat-raised-button mat-dialog-close color="warn">Close</button> 
    <button type="button" mat-raised-button color="accent" (click)="save()">Save</button>
</mat-dialog-actions>