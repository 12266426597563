<h2 mat-dialog-title>{{title}}</h2>
<form [formGroup]="form" (ngSubmit)="save()">
  <mat-dialog-content>
    <mat-form-field class="w-100">
      <mat-label>Payment Date</mat-label>
      <input matInput formControlName="paymentDate" required [matDatepicker]="picker">
      <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
      <mat-datepicker #picker></mat-datepicker>
      <mat-error *ngIf="paymentDateInput.invalid">{{paymentDateInputError}}</mat-error>
    </mat-form-field>
    <mat-form-field class="w-100">
      <mat-select required placeholder="Select payment method..." formControlName="paymentMethodId">        
        <mat-option *ngFor="let pm of paymentMethods" [value]="pm.paymentMethodId">{{pm.paymentMethodLabel}}</mat-option>
      </mat-select>
      <mat-error *ngIf="paymentMethodIdInput.invalid">{{paymentMethodIdInputError}}</mat-error>
    </mat-form-field>
    <mat-form-field class="w-100">
      <input matInput type="number" placeholder="Amount..." required formControlName="paymentAmount">
      <mat-error *ngIf="paymentAmountInput.invalid">{{paymentAmountInputError}}</mat-error>
    </mat-form-field>
    <mat-form-field class="w-100">
      <mat-label>Comment</mat-label>
      <textarea matInput placeholder="Payment Comment..." maxlength="256" formControlName="comment"></textarea>
    </mat-form-field>
  </mat-dialog-content>
  <mat-divider></mat-divider>
  <mat-dialog-actions align="end">
    <button type="button" mat-raised-button mat-dialog-close color="warn">Close</button> 
    <button type="submit" mat-raised-button color="accent">Save</button>
  </mat-dialog-actions>
</form>