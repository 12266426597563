import { Component, Inject, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { LocationModel } from 'src/app/models/location';
import { LocationService } from 'src/app/services/location.service';
import { ToastService } from 'src/app/services/toast.service';
import { Constants } from 'src/app/config/constant';

@Component({
  selector: 'app-edit-location-dlg',
  templateUrl: './edit-location-dlg.component.html',
  styleUrls: ['./edit-location-dlg.component.scss']
})
export class EditLocationDlgComponent implements OnInit {
  form: FormGroup;
  title:string;
  bCreateMode:boolean;

  constructor(
    private router: Router,
    private locationService: LocationService, 
    private dialogRef: MatDialogRef<EditLocationDlgComponent>,
    private fb: FormBuilder,
    private toastService: ToastService,
    @Inject(MAT_DIALOG_DATA) data:LocationModel
  ) { 
    this.form = this.fb.group({     
      clientId: [data.clientId],
      code: [data.code],
      description: [data.description],
      name: [data.name, [Validators.required]],
      latitude: [data.latitude],
      longtitude: [data.longtitude],
      position: [data.position],
      status: [data.status],
      locationId: [data.locationId],
      createdDate: [data.createdDate],
      modifiedDate: [data.modifiedDate],
      createdBy: [data.createdBy],
      modifiedBy: [data.modifiedBy],
    });
    if(data.locationId===0){
      this.title='Add Location';
      this.bCreateMode=true;
    }
    else{
      this.bCreateMode=false;
      this.title='Edit Office';
    }
  }

  ngOnInit(): void {
  }

  save(){
    if (this.form.valid) {
      this.locationService.saveLocation(
        this.form.value,
        this.onSave,
        this.onNotSave
      );
    }
  }

  close() {
    this.dialogRef.close();
  }

  onSave=(pro:LocationModel)=>{
    this.toastService.showSuccess(Constants.message.successSaved);
    this.dialogRef.close(pro);
  }

  onNotSave=(error: any) => {
    this.toastService.showResponseError(error);
    if(error.status == 401){
      this.router.navigate(['/auth/sign-in']);
    }
  }

  get nameInput(): any {return this.form.get('name'); }
  get nameInputError(): string | void {    
    if (this.nameInput.hasError('required')) { return Constants.message.requiredField; }
  }

  get codeInput(): any {return this.form.get('code'); }
  get codeInputError(): string | void {    
    if (this.codeInput.hasError('required')) { return Constants.message.requiredField; }
  }
}
