import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApiUrls } from '../config/api.endpoints';
import { Page } from '../models/page';
import { ProfessionalModel } from '../models/professional';
import { QueryParams, createHttpParams } from '../models/query-params';
import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root'
})
export class ProfessionalService {

  constructor(
    private http: HttpClient,
    private authService: AuthService
  ) { }

  getProfessional(professionalId:number, onSuccess:any, onError?:any){
    
    const httpOptions = {
      headers: new HttpHeaders({
        'x-auth-token': this.authService.token
      }),
    };
    this.http.get<ProfessionalModel>(ApiUrls.PROFESSIONALS+'/'+professionalId, httpOptions)
      .subscribe((data)=>{
        if(onSuccess)
          onSuccess(data);
      }, (error:HttpErrorResponse)=>{
        if(onError)
          onError(error.status);
      });
  }
  
  getProfessionalList(
    queryParams?: QueryParams,
    onSuccess?:any, onError?:any
  ){
    
    const httpOptions = {
      params: createHttpParams(queryParams),
      headers: new HttpHeaders({
        'x-auth-token': this.authService.token
      }),
    };

    this.http.get<Page<ProfessionalModel>>(ApiUrls.PROFESSIONALS, httpOptions)
      .subscribe((data)=>{
        if(onSuccess)
          onSuccess(data);
      }, (error:HttpErrorResponse)=>{
        if(onError)
          onError(error);
      });
  }

  deleteProfessional(
    professionalId:number, 
    onSuccess?:any, onError?:any){
    const httpOptions = {
      headers: new HttpHeaders({
        'x-auth-token': this.authService.token
      }),
    };

    this.http.delete<void>(ApiUrls.PROFESSIONALS+'/'+professionalId, httpOptions)
      .subscribe(()=>{
        if(onSuccess)
          onSuccess();
      }, (error:HttpErrorResponse)=>{
        if(onError)
          onError(error);
      });
  }

  undeleteProfessional(
    professionalId:number, 
    onSuccess?:any, onError?:any){
    const httpOptions = {
      headers: new HttpHeaders({
        'x-auth-token': this.authService.token
      }),
    };

    this.http.get<void>(ApiUrls.PROFESSIONALS+'/recycle/'+professionalId, httpOptions)
      .subscribe(()=>{
        if(onSuccess)
          onSuccess();
      }, (error:HttpErrorResponse)=>{
        if(onError)
          onError(error);
      });
  }

  saveProfessional(
    professional:ProfessionalModel,
    onSuccess?:any, onError?:any
  ){
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'x-auth-token': this.authService.token
      }),
    };

    this.http.post<ProfessionalModel>(ApiUrls.PROFESSIONALS, professional, httpOptions)
      .subscribe((data)=>{
        if(onSuccess)
          onSuccess(data);
      }, (error:HttpErrorResponse)=>{
        if(onError)
          onError(error);
      });
  }
}
