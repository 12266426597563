import { Component, Inject, OnInit } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import {MAT_MOMENT_DATE_FORMATS, MomentDateAdapter} from '@angular/material-moment-adapter';
import {DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE} from '@angular/material/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import * as moment from 'moment';
import { CLIENT_ID } from 'src/app/config/constant';
import { CustomerModel } from 'src/app/models/customer';
import { InvoiceModel } from 'src/app/models/invoice';
import { InvoiceLineModel } from 'src/app/models/invoice.line';
import { TaxCodeModel } from 'src/app/models/Tax.code';
import { InvoiceService } from 'src/app/services/invoice.service';
import { ToastService } from 'src/app/services/toast.service';
import { Constants } from 'src/app/config/constant';
import { Router } from '@angular/router';

export const MY_FORMATS = {
  parse: {
      dateInput: 'LL'
  },
  display: {
      dateInput: 'MM/DD/YYYY',
      monthYearLabel: 'YYYY',
      dateA11yLabel: 'LL',
      monthYearA11yLabel: 'YYYY'
  }
};


@Component({
  selector: 'app-edit-invoice-dlg',
  templateUrl: './edit-invoice-dlg.component.html',
  styleUrls: ['./edit-invoice-dlg.component.scss'],
  providers: [
    {provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE]},
    {provide: MAT_DATE_FORMATS, useValue: MY_FORMATS},
  ],
})
export class EditInvoiceDlgComponent implements OnInit {
  form: FormGroup;
  title:string;
  bCreateMode:boolean;
  invoiceData:InvoiceModel;
  taxes:TaxCodeModel[];
  customer:CustomerModel;
  error:string|null =null;

  displayedColumns=['time', 'customer'];

  constructor(
    private router: Router,
    private fb: FormBuilder,
    private invoiceService: InvoiceService,
    private toastService: ToastService,
    private dialogRef: MatDialogRef<EditInvoiceDlgComponent>,
    @Inject(MAT_DIALOG_DATA) data:any

  ) {
    this.invoiceData={...data.invoice};
    this.taxes = data.taxes;
    this.customer = data.customer;
    
    const invoiceDate = moment.parseZone(this.invoiceData.invoiceDate);
    const invoiceDueDate = moment.parseZone(this.invoiceData.invoiceDueDate);

    this.form= this.fb.group({
      email: [this.customer.email, Validators.required],
      invoiceDate: [invoiceDate, Validators.required],
      invoiceDueDate: [invoiceDueDate, Validators.required],
      invoiceLines: this.fb.array(
        this.createInvoiceLineFormArray(this.invoiceData.invoiceLines)
      )
    });

    const customerName = this.customer.firstname+' '+this.customer.lastname;
    if(this.invoiceData.invoiceId===0){      
      this.title='New Customer Invoice('+customerName+')';
      this.bCreateMode=true;
    }
    else{
      this.bCreateMode=false;
      this.title='Edit Customer Invoice('+customerName+')';
    }
  }

  ngOnInit(): void {
  }

  createInvoiceLineForm(invoiceLine?: InvoiceLineModel):FormGroup{
    if(!invoiceLine)
      invoiceLine = this.createEmptyInvoiceLine();

    return this.fb.group({
      amount	: [invoiceLine.amount],
      clientId	: [invoiceLine.clientId],
      customerId	: [invoiceLine.customerId],
      description: [invoiceLine.description, Validators.required],
      invoiceId	: [invoiceLine.invoiceId],
      invoiceLineId	: [invoiceLine.invoiceLineId],
      quantity: [invoiceLine.quantity, [Validators.required, Validators.min(1)]],
      rate:[invoiceLine.quantity, [Validators.required, Validators.min(0)]],
      sequence	: [invoiceLine.sequence],
      // serviceId	: [invoiceLine.serviceId],
      // serviceLabel	: [invoiceLine.serviceLabel],
      taxAmount	: [invoiceLine.taxAmount],
      taxCalculation	: [invoiceLine.taxCalculation],
      taxCode: [invoiceLine.taxCode, Validators.required],
      taxRate	: [invoiceLine.taxRate],
      totalAmount	: [invoiceLine.totalAmount],
      createdDate	: [invoiceLine.createdDate],
      modifiedDate	: [invoiceLine.modifiedDate],
      createdBy	: [invoiceLine.createdBy],
      modifiedBy	: [invoiceLine.modifiedBy]
    });
  }

  createInvoiceLineFormArray(invoiceLines?: InvoiceLineModel[]):FormGroup[]{
    if(!invoiceLines || invoiceLines.length==0)
    invoiceLines = [this.createEmptyInvoiceLine()];

    let packageFormArray:FormGroup[]=[];
    for(let i=0; i<invoiceLines.length; i++){
      packageFormArray.push(this.createInvoiceLineForm(invoiceLines[i]));
    }

    return packageFormArray;
  }

  createEmptyInvoiceLine(){
    const invoiceLine = {
      amount	: 0,
      clientId	: CLIENT_ID,
      customerId	: this.customer.customerId,
      description	: '',
      invoiceId	: this.invoiceData.invoiceId,
      invoiceLineId	: 0,
      quantity	: 1,
      rate	: 1,
      sequence	: 1,
      // serviceId	: 0,
      // serviceLabel	: '',
      taxAmount	: 0,
      taxCalculation	: '',
      taxCode	: '',
      taxRate	: 0,
      totalAmount	: 0,
      createdDate	: '',
      modifiedDate	: '',
      createdBy	: 0,
      modifiedBy	: 0
    };

    return invoiceLine;
  }

  AddInvoiceLine(){
    this.getInvoiceLineFormArray().push(this.createInvoiceLineForm());
  }

  removeRow(index:number){
    const formArray = this.getInvoiceLineFormArray();
    console.log(formArray.length);
    if(formArray.length>1)
      this.getInvoiceLineFormArray().removeAt(index);
  }

  getInvoiceLineFormArray(){
    const lines = this.form.controls.invoiceLines as FormArray;
    return lines;
  }

  save(){
    if (this.form.valid) {
      const editedValue=this.form.value;
      const invoiceLines=editedValue.invoiceLines as InvoiceLineModel[];

      this.invoiceData.invoiceDate = moment(editedValue.invoiceDate).format('YYYY-MM-DD');
      this.invoiceData.invoiceDueDate = moment(editedValue.invoiceDueDate).format('YYYY-MM-DD');
      
      let totalTax=0;
      let totalAmount=0;
      for(let i=0; i<invoiceLines.length;i++){
        const taxData=this.findTaxCodeData(invoiceLines[i].taxCode);
        if(taxData){
          invoiceLines[i].taxRate=taxData.taxRate;
          invoiceLines[i].taxCalculation=taxData.taxCalculation;
          invoiceLines[i].taxAmount=invoiceLines[i].quantity*taxData.taxRate/100;
          totalTax += invoiceLines[i].taxAmount;
        }
        invoiceLines[i].amount=invoiceLines[i].quantity*invoiceLines[i].rate;
        invoiceLines[i].totalAmount=invoiceLines[i].amount+invoiceLines[i].taxAmount;
        totalAmount+=invoiceLines[i].totalAmount;
        if(this.invoiceData.invoiceId == 0) {
          invoiceLines[i].invoiceId = null;
          invoiceLines[i].invoiceLineId = null;
        }
      }
      this.invoiceData.invoiceTotal= totalAmount;
      this.invoiceData.invoiceTax=totalTax;
      this.invoiceData.taxTotal = totalTax;

      this.invoiceData.invoiceLines=invoiceLines;

      if(this.invoiceData.invoiceId == 0) {
        this.invoiceData.invoiceId = null;
      }

      this.invoiceService.saveInvoice(
        this.invoiceData,
        this.onSave,
        this.onNotSave
      );
    }
  }

  close() {
    this.dialogRef.close();
  }

  onSave=(pro:InvoiceModel)=>{
    this.toastService.showSuccess(Constants.message.successSaved);
    this.dialogRef.close(pro);
  }

  onNotSave=(error: HttpErrorResponse) => {    
    this.toastService.showResponseError(error);
    if(error.status == 401){
      this.router.navigate(['/auth/sign-in']);
    }
  }

  calculateTotalTax():number{
    const invoiceLines = this.getInvoiceLineFormArray().value;
    let totalTax=0;
    for(let i=0; i<invoiceLines.length; i++){
      const tax=this.findTaxCodeData(invoiceLines[i].taxCode);
      if(tax){
        totalTax+=tax.taxRate*(invoiceLines[i].quantity*invoiceLines[i].rate)/100;
      }
    }
    return totalTax;
  }

  findTaxCodeData(taxCode:string){
    const len = this.taxes.length;
    for(let i=0; i<len; i++){
      if(this.taxes[i].taxCode == taxCode){
        return this.taxes[i];
      }
    }
    return null;
  }
  calculateTotalAmount():number{
    const invoiceLines = this.getInvoiceLineFormArray().value;
    let totalAmount=0;
    for(let i=0; i<invoiceLines.length; i++){
      const amount = invoiceLines[i].rate*invoiceLines[i].quantity;
      let tax=0;
      const taxData=this.findTaxCodeData(invoiceLines[i].taxCode);
      if(taxData){
        tax=amount*taxData.taxRate/100;
      }
      totalAmount += amount+tax;
    }
    return totalAmount;
  }  

  get emailInput(): any {return this.form.get('email'); }
  get emailInputError(): string | void {    
    if (this.emailInput.hasError('required')) { return Constants.message.requiredField; }
  }
  get invoiceDateInput(): any {return this.form.get('invoiceDate'); }
  get invoiceDateInputError(): string | void {    
    if (this.invoiceDateInput.hasError('required')) { return Constants.message.requiredField; }
  }
  get invoiceDueDateInput(): any {return this.form.get('invoiceDueDate'); }
  get invoiceDueDateInputError(): string | void {    
    if (this.invoiceDueDateInput.hasError('required')) { return Constants.message.requiredField; }
  }
}
