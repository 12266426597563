import { HttpClient, HttpErrorResponse, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApiUrls } from '../config/api.endpoints';
import { EventModel } from '../models/event';
import { Page } from '../models/page';
import { QueryParams, createHttpParams } from '../models/query-params';
import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root'
})
export class EventService {
  constructor(
    private http: HttpClient,
    private authService: AuthService
  ) { }

  getEvent(eventId:number, onSuccess:any, onError?:any){
    
    const httpOptions = {
      headers: new HttpHeaders({
        'x-auth-token': this.authService.token
      }),
    };
    this.http.get<EventModel>(ApiUrls.EVENTS+'/'+eventId, httpOptions)
      .subscribe((data)=>{
        if(onSuccess)
          onSuccess(data);
      }, (error:HttpErrorResponse)=>{
        if(onError)
          onError(error.status);
      });
  }
  
  getEvents(
    queryParams?: QueryParams,
    onSuccess?:any, onError?:any
  ){
    
    const httpOptions = {
      params: createHttpParams(queryParams),
      headers: new HttpHeaders({
        'x-auth-token': this.authService.token
      }),
    };

    this.http.get<Page<EventModel>>(ApiUrls.EVENTS, httpOptions)
      .subscribe((data)=>{
        if(onSuccess)
          onSuccess(data);
      }, (error:HttpErrorResponse)=>{
        if(onError)
          onError(error.status);
      });
  }

  getEventsByRecordType(
    recordType:string,
    queryParams?: QueryParams,
    onSuccess?:any, onError?:any
  ){
    
    const httpOptions = {
      params: createHttpParams(queryParams),
      headers: new HttpHeaders({
        'x-auth-token': this.authService.token
      }),
    };

    this.http.get<Page<EventModel>>(ApiUrls.EVENTS+'/record/'+recordType, httpOptions)
      .subscribe((data)=>{
        if(onSuccess)
          onSuccess(data);
      }, (error:HttpErrorResponse)=>{
        if(onError)
          onError(error.status);
      });
  }

  getEventsByCompany(
    companyId:number,
    queryParams?: QueryParams,
    onSuccess?:any, onError?:any
  ){
    
    const httpOptions = {
      params: createHttpParams(queryParams),
      headers: new HttpHeaders({
        'x-auth-token': this.authService.token
      }),
    };

    this.http.get<Page<EventModel>>(ApiUrls.EVENTS+'/company/'+companyId, httpOptions)
      .subscribe((data)=>{
        if(onSuccess)
          onSuccess(data);
      }, (error:HttpErrorResponse)=>{
        if(onError)
          onError(error.status);
      });
  }

  getEventsByCompanyAndDate(
    companyId:number, fromDate:string, toDate:string,
    queryParams?: QueryParams,
    onSuccess?:any, onError?:any
  ){
    const httpParams =  createHttpParams(queryParams);
    httpParams.set('toDate', toDate);
    httpParams.set('fromDate', fromDate);

    const httpOptions = {
      params: httpParams,
      headers: new HttpHeaders({
        'x-auth-token': this.authService.token
      }),
    };

    this.http.get<Page<EventModel>>(ApiUrls.EVENTS+'/company/'+companyId+'/search', httpOptions)
      .subscribe((data)=>{
        if(onSuccess)
          onSuccess(data);
      }, (error:HttpErrorResponse)=>{
        if(onError)
          onError(error.status);
      });
  }

  deleteEvent(
    eventId:number, 
    onSuccess?:any, onError?:any){
    const httpOptions = {
      headers: new HttpHeaders({
        'x-auth-token': this.authService.token
      }),
    };

    this.http.delete<void>(ApiUrls.EVENTS+'/'+eventId, httpOptions)
      .subscribe(()=>{
        if(onSuccess)
          onSuccess();
      }, (error:HttpErrorResponse)=>{
        if(onError)
          onError(error.status, error);
      });
  }

  undeleteEvent(
    eventId:number, 
    onSuccess?:any, onError?:any){
    const httpOptions = {
      headers: new HttpHeaders({
        'x-auth-token': this.authService.token
      }),
    };

    this.http.get<void>(ApiUrls.EVENTS+'/recycle/'+eventId, httpOptions)
      .subscribe(()=>{
        if(onSuccess)
          onSuccess();
      }, (error:HttpErrorResponse)=>{
        if(onError)
          onError(error.status, error);
      });
  }

  getEventsByCustomer(
    customerId:number,
    queryParams?: QueryParams,
    onSuccess?:any, onError?:any
  ){
    
    const httpOptions = {
      params: createHttpParams(queryParams),
      headers: new HttpHeaders({
        'x-auth-token': this.authService.token
      }),
    };

    this.http.get<Page<EventModel>>(ApiUrls.EVENTS+'/customer/'+customerId, httpOptions)
      .subscribe((data)=>{
        if(onSuccess)
          onSuccess(data);
      }, (error:HttpErrorResponse)=>{
        if(onError)
          onError(error.status);
      });
  }

  getEventsByCustomerAndDate(
    customerId:number, fromDate:string, toDate:string,
    queryParams?: QueryParams,
    onSuccess?:any, onError?:any
  ){
    const httpParams =  createHttpParams(queryParams);
    httpParams.set('toDate', toDate);
    httpParams.set('fromDate', fromDate);

    const httpOptions = {
      params: httpParams,
      headers: new HttpHeaders({
        'x-auth-token': this.authService.token
      }),
    };

    this.http.get<Page<EventModel>>(ApiUrls.EVENTS+'/customer/'+customerId+'/search', httpOptions)
      .subscribe((data)=>{
        if(onSuccess)
          onSuccess(data);
      }, (error:HttpErrorResponse)=>{
        if(onError)
          onError(error.status);
      });
  }

  getEventsByOffice(
    officeId:number,
    queryParams?: QueryParams,
    onSuccess?:any, onError?:any
  ){
    
    const httpOptions = {
      params: createHttpParams(queryParams),
      headers: new HttpHeaders({
        'x-auth-token': this.authService.token
      }),
    };

    this.http.get<Page<EventModel>>(ApiUrls.EVENTS+'/office/'+officeId, httpOptions)
      .subscribe((data)=>{
        if(onSuccess)
          onSuccess(data);
      }, (error:HttpErrorResponse)=>{
        if(onError)
          onError(error.status);
      });
  }

  getEventsByProfessional(
    professionalId:number,
    queryParams?: QueryParams,
    onSuccess?:any, onError?:any
  ){
    
    const httpOptions = {
      params: createHttpParams(queryParams),
      headers: new HttpHeaders({
        'x-auth-token': this.authService.token
      }),
    };

    this.http.get<Page<EventModel>>(ApiUrls.EVENTS+'/professional/'+professionalId, httpOptions)
      .subscribe((data)=>{
        if(onSuccess)
          onSuccess(data);
      }, (error:HttpErrorResponse)=>{
        if(onError)
          onError(error.status);
      });
  }

  getEventsByProfessionalAndDate(
    prfessionalId:number, fromDate:string, toDate:string,
    queryParams?: QueryParams,
    onSuccess?:any, onError?:any
  ){
    let httpParams =  createHttpParams(queryParams);
    httpParams = httpParams.set('toDate', toDate);
    httpParams = httpParams.set('fromDate', fromDate);

    const httpOptions = {
      params: httpParams,
      headers: new HttpHeaders({
        'x-auth-token': this.authService.token
      }),
    };

    this.http.get<Page<EventModel>>(ApiUrls.EVENTS+'/professional/'+prfessionalId+'/search', httpOptions)
      .subscribe((data)=>{
        if(onSuccess)
          onSuccess(data);
      }, (error:HttpErrorResponse)=>{
        if(onError)
          onError(error.status, error);
      });
  }

  saveEvent(
    event:EventModel,
    onSuccess?:any, onError?:any
  ){
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'x-auth-token': this.authService.token
      }),
    };

    this.http.post<EventModel>(ApiUrls.EVENTS, event, httpOptions)
      .subscribe((data)=>{
        if(onSuccess)
          onSuccess(data);
      }, (error:HttpErrorResponse)=>{
        if(onError)
          onError(error);
      });
  }

  undoDeleteEvent(eventId:number, onSuccess:any, onError?:any){
    
    const httpOptions = {
      headers: new HttpHeaders({
        'x-auth-token': this.authService.token
      }),
    };
    this.http.get<EventModel>(ApiUrls.EVENTS+'/recycle/'+eventId, httpOptions)
      .subscribe((user)=>{
        if(onSuccess)
          onSuccess();
      }, (error:HttpErrorResponse)=>{
        if(onError)
          onError(error.status);
      });
  }

}
