import {
  HttpClient,
  HttpErrorResponse,
  HttpHeaders,
  HttpParams,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApiUrls } from '../config/api.endpoints';
import { Page } from '../models/page';
import { QueryParams, createHttpParams } from '../models/query-params';
import { ServiceModel } from '../models/service';
import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root',
})
export class ServiceService {
  constructor(private http: HttpClient, private authService: AuthService) {}

  getService(serviceId: number, onSuccess: any, onError?: any) {
    const httpOptions = {
      headers: new HttpHeaders({
        'x-auth-token': this.authService.token,
      }),
    };
    this.http
      .get<ServiceModel>(ApiUrls.SERVICES + '/' + serviceId, httpOptions)
      .subscribe(
        (data) => {
          if (onSuccess) onSuccess(data);
        },
        (error: HttpErrorResponse) => {
          if (onError) onError(error.status);
        }
      );
  }

  getServiceList(queryParams?: QueryParams, onSuccess?: any, onError?: any) {
    const httpOptions = {
      params: createHttpParams(queryParams),
      headers: new HttpHeaders({
        'x-auth-token': this.authService.token,
      }),
    };

    this.http.get<Page<ServiceModel>>(ApiUrls.SERVICES, httpOptions).subscribe(
      (data) => {
        if (onSuccess) onSuccess(data);
      },
      (error: HttpErrorResponse) => {
        if (onError) onError(error);
      }
    );
  }

  getServiceByCategory(
    categoryId: string,
    queryParams?: QueryParams,
    onSuccess?: any,
    onError?: any
  ) {
    const httpOptions = {
      params: createHttpParams(queryParams),
      headers: new HttpHeaders({
        'x-auth-token': this.authService.token,
      }),
    };

    this.http
      .get<Page<ServiceModel>>(
        ApiUrls.SERVICES + '/category/' + categoryId,
        httpOptions
      )
      .subscribe(
        (data) => {
          if (onSuccess) onSuccess(data);
        },
        (error: HttpErrorResponse) => {
          if (onError) onError(error.status);
        }
      );
  }

  getServiceByCompany(
    companyId: string,
    queryParams?: QueryParams,
    onSuccess?: any,
    onError?: any
  ) {
    const httpOptions = {
      params: createHttpParams(queryParams),
      headers: new HttpHeaders({
        'x-auth-token': this.authService.token,
      }),
    };

    this.http
      .get<Page<ServiceModel>>(
        ApiUrls.SERVICES + '/company/' + companyId,
        httpOptions
      )
      .subscribe(
        (data) => {
          if (onSuccess) onSuccess(data);
        },
        (error: HttpErrorResponse) => {
          if (onError) onError(error.status);
        }
      );
  }

  deleteService(serviceId: number, onSuccess?: any, onError?: any) {
    const httpOptions = {
      headers: new HttpHeaders({
        'x-auth-token': this.authService.token,
      }),
    };

    this.http
      .delete<void>(ApiUrls.SERVICES + '/' + serviceId, httpOptions)
      .subscribe(
        () => {
          if (onSuccess) onSuccess();
        },
        (error: HttpErrorResponse) => {
          if (onError) onError(error.status);
        }
      );
  }

  undeleteService(serviceId: number, onSuccess?: any, onError?: any) {
    const httpOptions = {
      headers: new HttpHeaders({
        'x-auth-token': this.authService.token,
      }),
    };

    this.http
      .get<void>(ApiUrls.SERVICES + '/recycle/' + serviceId, httpOptions)
      .subscribe(
        () => {
          if (onSuccess) onSuccess();
        },
        (error: HttpErrorResponse) => {
          if (onError) onError(error.status);
        }
      );
  }

  saveService(service: ServiceModel, onSuccess?: any, onError?: any) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'x-auth-token': this.authService.token,
      }),
    };

    this.http
      .post<ServiceModel>(ApiUrls.SERVICES, service, httpOptions)
      .subscribe(
        (data) => {
          // data is not correct here and nothing is different for the price feild
          console.log('save service', data);
          if (onSuccess) onSuccess(data);
        },
        (error: HttpErrorResponse) => {
          if (onError) onError(error.status);
        }
      );
  }
}
