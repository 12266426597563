<h2 mat-dialog-title>{{title}}</h2>
<form [formGroup]="form" (ngSubmit)="save()">
  <mat-dialog-content>  
    <mat-form-field class="w-100">
      <mat-label>Title</mat-label>
      <mat-select formControlName="title" required>
        <mat-option *ngFor="let t of customerTitles" [value]="t">{{t}}</mat-option>
      </mat-select>
      <mat-error *ngIf="titleInput.invalid">{{titleInputError}}</mat-error>
    </mat-form-field>
    <mat-form-field class="w-100">
      <mat-label>First Name</mat-label>
      <input matInput type="text" placeholder="First Name" formControlName="firstname" required>
      <mat-error *ngIf="firstnameInput.invalid">{{firstnameInputError}}</mat-error>
    </mat-form-field>
    <mat-form-field class="w-100">
      <mat-label>Last Name</mat-label>
      <input matInput type="text" placeholder="Last Name" formControlName="lastname" required>
      <mat-error *ngIf="lastnameInput.invalid">{{lastnameInputError}}</mat-error>
    </mat-form-field>
    <mat-form-field class="w-100">
      <mat-label>Gender</mat-label>
      <mat-select formControlName="gender" required>      
        <mat-option value="M"> Male </mat-option>
        <mat-option value="F"> Female </mat-option>
      </mat-select>
      <mat-error *ngIf="genderInput.invalid">{{genderInputError}}</mat-error>
    </mat-form-field>
    <mat-form-field class="w-100">
      <mat-label>Cell</mat-label>
      <input matInput  type="text" placeholder="Cell Phone Number" formControlName="cellPhone" required>
      <mat-error *ngIf="phoneInput.invalid">{{phoneInputError}}</mat-error>
    </mat-form-field>
    <mat-form-field class="w-100">
      <mat-label>Home Phone</mat-label>
      <input matInput type="text" placeholder="Home Phone Number" formControlName="homePhone">
    </mat-form-field>
    <mat-form-field class="w-100">
      <mat-label>Work Phone</mat-label>
      <input matInput type="text" placeholder="Work Phone" formControlName="workPhone">
    </mat-form-field>
    <mat-form-field class="w-100">
      <mat-label>Email</mat-label>
      <input matInput type="email" placeholder="Email" formControlName="email" required>
      <mat-error *ngIf="emailInput.invalid">{{emailInputError}}</mat-error>
    </mat-form-field>

    <input type="hidden" formControlName="bdate" />
    <input type="hidden" formControlName="clientId" />
    <input type="hidden" formControlName="code" />
    <input type="hidden" formControlName="companyId" />
    <input type="hidden" formControlName="companyName" />
    <input type="hidden" formControlName="customerId" />
    <input type="hidden" formControlName="receiveEmail" />
    <input type="hidden" formControlName="receiveSms" />
    <input type="hidden" formControlName="reference" />
    <input type="hidden" formControlName="status" />
    <input type="hidden" formControlName="createdDate" />
    <input type="hidden" formControlName="modifiedDate" />
    <input type="hidden" formControlName="createdBy" />
    <input type="hidden" formControlName="modifiedBy" />
  </mat-dialog-content>
  <mat-divider></mat-divider>
  <mat-dialog-actions align="end">
      <button type="button" mat-raised-button mat-dialog-close color="warn">Close</button> 
      <button type="submit" mat-raised-button color="accent">Save</button>
  </mat-dialog-actions>
</form>