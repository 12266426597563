<h2 mat-dialog-title>{{title}}</h2>

<mat-dialog-content>
    <h3 *ngIf="subject">{{subject}}</h3>
    <p *ngIf="text">{{ text }}</p>
</mat-dialog-content>

<mat-dialog-actions align="end">  
  <button type="button" mat-raised-button mat-dialog-close color="accent">No</button>
  <button type="button" mat-raised-button color="warn" (click)="confirm()">Yes</button>
</mat-dialog-actions>