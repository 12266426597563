<h2 mat-dialog-title>{{title}}</h2>
    <form [formGroup]="form" (ngSubmit)="save()">
    <mat-dialog-content>
        <mat-form-field class="w-100">
            <mat-label>Service Code</mat-label>
            <input matInput
                    type="text"
                    placeholder="CODE"
                    required
                formControlName="code">
            <mat-error *ngIf="codeInput.invalid">{{codeInputError}}</mat-error>
        </mat-form-field>
        <mat-form-field class="w-100">
            <mat-label>Service Label</mat-label>
            <input matInput
                    type="text"
                    placeholder="Label"
                    required
                formControlName="label">
            <mat-error *ngIf="labelInput.invalid">{{labelInputError}}</mat-error>
        </mat-form-field>
        <mat-form-field class="w-100">
            <mat-label>Service Price</mat-label>
            <input matInput
                    type="number"
                    placeholder="00.00"
                    min="1"
                    required
                    appTwoDigitDecimaNumber
                formControlName="price">
            <mat-error *ngIf="priceInput.invalid">{{priceInputError}}</mat-error>
        </mat-form-field>
        <mat-form-field class="w-100">
            <mat-label>Service Description</mat-label>
            <input matInput
                    type="text"
                    placeholder="Description..."
                formControlName="description">
        </mat-form-field>
        <mat-form-field class="w-100">
            <mat-label>Category</mat-label>
            <mat-select
                    placeholder="Select Category"
                    required
                formControlName="category">
                <mat-option value="ALL">All</mat-option>
                <mat-option value="ADMIN">Admin</mat-option>
            </mat-select>
            <mat-error *ngIf="categoryInput.invalid">{{categoryInputError}}</mat-error>
        </mat-form-field>
        <mat-form-field class="w-100">
            <mat-label>Status</mat-label>
            <mat-select
                    placeholder="Select Status"
                    required
                formControlName="status">
                <mat-option value="ACTIVE">Active</mat-option>
                <mat-option value="INACTIVE">Inactive</mat-option>
            </mat-select>
            <mat-error *ngIf="statusInput.invalid">{{statusInputError}}</mat-error>
        </mat-form-field>
        <input type="hidden" formControlName="clientId" />
        <input type="hidden" formControlName="serviceId" />
        <input type="hidden" formControlName="companyId" />
        <input type="hidden" formControlName="taxCode" />
        <input type="hidden" formControlName="createdDate" />
        <input type="hidden" formControlName="modifiedDate" />
        <input type="hidden" formControlName="createdBy" />
        <input type="hidden" formControlName="modifiedBy" />
    </mat-dialog-content>
    <mat-divider></mat-divider>
    <mat-dialog-actions align="end">
        <button type="button" mat-raised-button mat-dialog-close color="warn">Close</button> 
        <button type="submit" mat-raised-button color="accent">Save</button>
    </mat-dialog-actions>
</form>