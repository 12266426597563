import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CompanyModel } from 'src/app/models/company';

@Component({
  selector: 'app-select-company-dlg',
  templateUrl: './select-company-dlg.component.html',
  styleUrls: ['./select-company-dlg.component.scss']
})
export class SelectCompanyDlgComponent implements OnInit {
  companyList:CompanyModel[];
  form: FormGroup;

  constructor(
    private fb: FormBuilder,
    private dialogRef: MatDialogRef<SelectCompanyDlgComponent>,
    @Inject(MAT_DIALOG_DATA) data:any
  ) {
    this.companyList=data.companyList;
    this.form= this.fb.group({
      myCompany: [data.selected.companyId, Validators.required]
    });
  }

  ngOnInit(): void {
  }

  save(){
    if (this.form.valid) {
      this.dialogRef.close(this.form.value.myCompany);
    }
  }

  close() {
    this.dialogRef.close();
  }
}
