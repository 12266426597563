import {
  HttpClient,
  HttpHeaders,
  HttpErrorResponse,
  HttpParams,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApiUrls } from '../config/api.endpoints';
import { Page } from '../models/page';
import { PasswordModel } from '../models/password';
import { QueryParams, createHttpParams } from '../models/query-params';
import { UserModel } from '../models/user';
import { UserAccessModel } from '../models/user.access';
import { UserAccessUpdateModel } from '../models/user.access.update';
import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  static USER_DATA_KEY = 'FLN_USER_DATA';
  public userData: UserModel | null = null;

  constructor(private http: HttpClient, private authService: AuthService) {
    this.readUserData();
  }

  getUser(username: string, onSuccess: any, onError?: any) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'x-auth-token': this.authService.token,
      }),
    };
    this.http
      .get<UserModel>(ApiUrls.USERS + '/' + username, httpOptions)
      .subscribe(
        (user) => {
          if (onSuccess) onSuccess(user);
        },
        (error: HttpErrorResponse) => {
          if (onError) onError(error.status);
        }
      );
  }
  getUserList(queryParams?: QueryParams, onSuccess?: any, onError?: any) {
    const httpOptions = {
      params: createHttpParams(queryParams),
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'x-auth-token': this.authService.token,
      }),
    };

    this.http.get<Page<UserModel>>(ApiUrls.USERS, httpOptions).subscribe(
      (data) => {
        console.log(data);

        if (onSuccess) onSuccess(data);
      },
      (error: HttpErrorResponse) => {
        if (onError) onError(error);
      }
    );
  }

  saveUser(user: UserModel, onSuccess?: any, onError?: any) {
    console.log('saveUser');

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'x-auth-token': this.authService.token,
      }),
    };

    this.http.post<UserModel>(ApiUrls.USERS, user, httpOptions).subscribe(
      (data) => {
        console.log(data);

        if (onSuccess) {
          onSuccess(data);
          console.log('success');
        }
      },
      (error: HttpErrorResponse) => {
        if (onError) {
          console.log('error', error.status);
          onError(error.status);
        }
      }
    );
  }

  deleteUser(userId: number, onSuccess?: any, onError?: any) {
    const httpOptions = {
      headers: new HttpHeaders({
        'x-auth-token': this.authService.token,
      }),
    };

    this.http.delete<void>(ApiUrls.USERS + '/' + userId, httpOptions).subscribe(
      () => {
        if (onSuccess) onSuccess();
      },
      (error: HttpErrorResponse) => {
        if (onError) onError(error.status);
      }
    );
  }

  undeleteUser(userId: number, onSuccess?: any, onError?: any) {
    const httpOptions = {
      headers: new HttpHeaders({
        'x-auth-token': this.authService.token,
      }),
    };

    this.http
      .get<void>(ApiUrls.USERS + '/recycle/' + userId, httpOptions)
      .subscribe(
        () => {
          if (onSuccess) onSuccess();
        },
        (error: HttpErrorResponse) => {
          if (onError) onError(error.status);
        }
      );
  }

  getUserAccess(username: string, onSuccess: any, onError?: any) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'x-auth-token': this.authService.token,
      }),
    };
    this.http
      .get<UserAccessModel[]>(
        ApiUrls.USERS + '/' + username + '/access',
        httpOptions
      )
      .subscribe(
        (accessList) => {
          if (onSuccess) onSuccess(accessList);
        },
        (error: HttpErrorResponse) => {
          if (onError) onError(error.status);
        }
      );
  }

  addUserAccess(
    username: string,
    userAccessList: UserAccessUpdateModel,
    onSuccess: any,
    onError?: any
  ) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'x-auth-token': this.authService.token,
      }),
    };

    this.http
      .post<UserAccessModel[]>(
        ApiUrls.USERS + '/' + username + '/access',
        userAccessList,
        httpOptions
      )
      .subscribe(
        (data) => {
          if (onSuccess) onSuccess(data);
        },
        (error: HttpErrorResponse) => {
          if (onError) onError(error.status);
        }
      );
  }

  changePassword(
    oldPass: string,
    newPass: string,
    onSuccess?: any,
    onError?: any
  ) {
    const body: PasswordModel = {
      currentPassword: oldPass,
      newPassword: newPass,
    };
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'x-auth-token': this.authService.token,
      }),
    };

    this.http
      .post<void>(ApiUrls.USERS + '/password', body, httpOptions)
      .subscribe(
        () => {
          if (onSuccess) onSuccess();
        },
        (error: HttpErrorResponse) => {
          if (onError) onError(error);
        }
      );
  }

  resetPassword(
    userName: string,
    oldPass: string,
    newPass: string,
    onSuccess?: any,
    onError?: any
  ) {
    const body: PasswordModel = {
      currentPassword: oldPass,
      newPassword: newPass,
    };
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'x-auth-token': this.authService.token,
      }),
    };

    this.http
      .post<void>(ApiUrls.USERS + '/password/' + userName, body, httpOptions)
      .subscribe(
        () => {
          if (onSuccess) onSuccess();
        },
        (error: HttpErrorResponse) => {
          if (onError) onError(error);
        }
      );
  }

  readUserData() {
    try {
      const strUserData = localStorage.getItem(UserService.USER_DATA_KEY);
      if (strUserData) {
        this.userData = JSON.parse(strUserData);
      }
    } catch (error) {
      this.userData = null;
    }
  }
  saveUserData() {
    if (this.userData)
      localStorage.setItem(
        UserService.USER_DATA_KEY,
        JSON.stringify(this.userData)
      );
  }
  removeUserData() {
    localStorage.removeItem(UserService.USER_DATA_KEY);
    this.userData = null;
  }
}
