<h1 mat-dialog-title>Select Company</h1>

<mat-dialog-content [formGroup]="form">
  <mat-form-field class="w-100">
      <mat-label>Company</mat-label>
      <mat-select
            placeholder="Select company..."
            formControlName="myCompany">
        <mat-option *ngFor="let cmp of companyList" value="{{cmp.companyId}}">{{cmp.code}} - {{cmp.name}}</mat-option>
      </mat-select>
  </mat-form-field>
</mat-dialog-content>
<mat-divider></mat-divider>
<mat-dialog-actions align="end">
  <button mat-button mat-dialog-close>Cancel</button> 
  <button mat-button [mat-dialog-close]="true" color="accent" (click)="save()">OK</button>
</mat-dialog-actions>