export const START_HOUR=7;
export const WORK_HOURS=10;
export const CLIENT_ID = 1;
export const Constants = {
    message: {
        successLogin: 'Successful login',
        successSaved: 'Saved successfully',
        invalidEmail: 'Please enter valid email address',        
        minLengthPwd: 'Password must be longer than ? letters',
        maxLengthPwd: 'Password must be less than ? letters',
        duplicatedEmail: 'This email is already in use',        
        unknownError: 'Unknown error was occured.',
        requiredField: 'This field is required',
        invalidPattern: 'Invalid pattern',        
        invalidMinValue1: 'This value must be greater than 1',
        invalidNoteMinLength: 'Minimum length is ? characters',
        invalidNoteMaxLength: 'Maximum length is ? characters',
    },
    password: {
        minLength: 6,
        maxLength: 20
    },
    note: {
        minLength: 5,
        maxLength: 500
    }
}