import { Injectable } from '@angular/core';
import {ToastrService} from 'ngx-toastr';
import { Constants } from '../config/constant';
import { CustomToastComponent } from '../components/custom-toast/custom-toast.component';

@Injectable({
    providedIn: 'root'
})

export class ToastService {

    constructor(private toastService: ToastrService) { }
    
    showSuccess(msg:string, title:string='Success'): any {      
      this.toastService.success(msg, title);
    }
  
    showError(msg:string, title:string='Error'): any {
      this.toastService.error(msg, title);
    }
  
    showResponseError(error: any) {
      let msg = Constants.message.unknownError;    
      if(typeof error.error != 'undefined') {
        msg = error.error.message;
      }      
      this.toastService.error(msg, 'Error');
    }

    showSuccessDelete(onUndo?: any) : any {
      this.toastService.success('', '', {
        closeButton: true,
        toastComponent: CustomToastComponent,        
        tapToDismiss: false,
      }).onAction.subscribe(()=>{
        if(onUndo) onUndo();
      })
    }
}