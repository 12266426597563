<h2 mat-dialog-title>Delete Appointment</h2>

<mat-dialog-content>
  <p>Are you sure you want to delete this appointment?</p>
  <div fxFill fxLayout="column" class="info-group">
    <div class="info-item">
      <span class="label">Service:</span>
      <span class="value">{{event.eventServices[0].serviceLabel}}</span>
    </div>
    <div class="info-item">
      <span class="label">Customer:</span>
      <span class="value">{{customer.firstname}} {{customer.lastname}}</span>
    </div>
    <div class="info-item">
      <span class="label">Date:</span>
      <span class="value">{{event.startTime| date:'E, LLL d'}}</span>
    </div>
  </div>
</mat-dialog-content>

<mat-dialog-actions align="end">
  <button mat-button mat-dialog-close>Cancel</button>
  <button mat-button color="accent" (click)="delete()">Delete</button>
</mat-dialog-actions>