import { Component, Inject, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { TaxCodeModel } from 'src/app/models/Tax.code';
import { TaxCodeService } from 'src/app/services/tax-code.service';
import { ToastService } from 'src/app/services/toast.service';
import { Constants } from 'src/app/config/constant';

@Component({
  selector: 'app-edit-tax-code-dlg',
  templateUrl: './edit-tax-code-dlg.component.html',
  styleUrls: ['./edit-tax-code-dlg.component.scss']
})
export class EditTaxCodeDlgComponent implements OnInit {
  form: FormGroup;
  title:string;
  bCreateMode:boolean;

  constructor(
    private router: Router,
    private taxService: TaxCodeService, 
    private dialogRef: MatDialogRef<EditTaxCodeDlgComponent>,
    private fb: FormBuilder,
    private toastService: ToastService,
    @Inject(MAT_DIALOG_DATA) data:TaxCodeModel
  ) { 
    this.form = this.fb.group({
      clientId: [data.clientId],
      comment: [data.comment],
      companyId: [data.companyId],
      taxCalculation: [data.taxCalculation, [Validators.required]],
      taxCode: [data.taxCode, [Validators.required]],
      taxCodeId: [data.taxCodeId],
      taxLabel: [data.taxLabel, [Validators.required]],
      taxRate: [data.taxRate],
      createdDate: [data.createdDate],
      modifiedDate: [data.modifiedDate],
      createdBy: [data.createdBy],
      modifiedBy: [data.modifiedBy],
    });
    if(data.taxCodeId===0){
      this.title='Add Tax Code';
      this.bCreateMode=true;
    }
    else{
      this.bCreateMode=false;
      this.title='Edit Tax Code';
    }
  }

  ngOnInit(): void {
  }

  save(){
    if (this.form.valid) {
      this.taxService.saveTaxCode(
        this.form.value,
        this.onSave,
        this.onNotSave
      );
    }
  }

  close() {
    this.dialogRef.close();
  }

  onSave=(pro:TaxCodeModel)=>{
    this.toastService.showSuccess(Constants.message.successSaved);
    this.dialogRef.close(pro);
  }

  onNotSave=(error: any) => {
    this.toastService.showResponseError(error);
    if(error.status == 401){
      this.router.navigate(['/auth/sign-in']);
    }
  }

  get taxCalculationInput(): any {return this.form.get('taxCalculation'); }
  get taxCalculationInputError(): string | void {    
    if (this.taxCalculationInput.hasError('required')) { return Constants.message.requiredField; }
  }

  get taxCodeInput(): any {return this.form.get('taxCode'); }
  get taxCodeInputError(): string | void {    
    if (this.taxCodeInput.hasError('required')) { return Constants.message.requiredField; }
  }

  get taxLabelInput(): any {return this.form.get('taxLabel'); }
  get taxLabelInputError(): string | void {    
    if (this.taxLabelInput.hasError('required')) { return Constants.message.requiredField; }
  }
}
