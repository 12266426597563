<h2 mat-dialog-title>Reset Password of {{username}}</h2>
<form [formGroup]="form" (ngSubmit)="save()">
    <mat-dialog-content>
        <mat-form-field class="w-100">
            <mat-label>New Password</mat-label>
            <input matInput
                    type="password"
                    placeholder="New Password"
                    required
                formControlName="newPassword">
            <mat-error *ngIf="newPasswordInput.invalid">{{newPasswordInputError}}</mat-error>
        </mat-form-field>
        <mat-form-field class="w-100">
            <mat-label>Confirm</mat-label>
            <input matInput
                    type="password"
                    placeholder="Confirm"
                    required
                    formControlName="confirm" [errorStateMatcher]="errorMatcher">
            <mat-error *ngIf="form.hasError('passwordsDoNotMatch')">
                Passwords do not match!
            </mat-error>
        </mat-form-field>
    </mat-dialog-content>

    <mat-dialog-actions align="end">
        <button type="button" mat-raised-button mat-dialog-close color="warn">Close</button> 
        <button type="submit" mat-raised-button color="accent">Save</button>
    </mat-dialog-actions>
</form>