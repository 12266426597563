import { Component, Inject, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { CustomerModel } from 'src/app/models/customer';
import { CustomerService } from 'src/app/services/customer.service';
import { ToastService } from 'src/app/services/toast.service';
import { Constants } from 'src/app/config/constant';

@Component({
  selector: 'app-edit-customer-dlg',
  templateUrl: './edit-customer-dlg.component.html',
  styleUrls: ['./edit-customer-dlg.component.scss']
})
export class EditCustomerDlgComponent implements OnInit {
  form: FormGroup;
  title:string;
  bCreateMode:boolean;
  customerTitles = ['Mr.', 'Miss.', 'Mrs.', 'Ms.'];

  constructor(
    private router: Router,
    private customerService: CustomerService, 
    private dialogRef: MatDialogRef<EditCustomerDlgComponent>,
    private fb: FormBuilder,
    private toastService: ToastService,
    @Inject(MAT_DIALOG_DATA) data:CustomerModel
  ) { 
    this.form= this.fb.group({
      bdate: [data.bdate],
      cellPhone: [data.cellPhone, [Validators.required]],
      clientId: [data.clientId],
      code: [data.code],
      companyId: [data.companyId],
      companyName: [data.companyName],
      customerId: [data.customerId],
      email: [data.email, [Validators.required, Validators.email]],
      firstname: [data.firstname, [Validators.required]],
      gender: [data.gender, [Validators.required]],
      homePhone: [data.homePhone],
      lastname: [data.lastname, [Validators.required]],
      receiveEmail: [data.receiveEmail],
      receiveSms: [data.receiveSms],
      reference: [data.reference],
      status: [data.status],
      workPhone: [data.workPhone],
      title: [data.title, [Validators.required]],
      createdDate: [data.createdDate],
      modifiedDate: [data.modifiedDate],
      createdBy: [data.createdBy],
      modifiedBy: [data.modifiedBy],
    });
    if(data.customerId===0){
      this.title='Add Customer';
      this.bCreateMode=true;
    }
    else{
      this.bCreateMode=false;
      this.title='Edit Customer';
    }
  }

  ngOnInit(): void {
  }

  save(){    
    if (this.form.valid) {
      this.customerService.saveCustomer(
        this.form.value,
        this.onSave,
        this.onNotSave
      );
    }
  }

  close() {
    this.dialogRef.close();
  }

  onSave=(pro:CustomerModel)=>{
    this.toastService.showSuccess(Constants.message.successSaved);
    this.dialogRef.close(pro);
  }

  onNotSave=(error: any) => {    
    this.toastService.showResponseError(error);
    if(error.status == 401){
      this.router.navigate(['/auth/sign-in']);
    }
  }

  get phoneInput(): any {return this.form.get('cellPhone'); }
  get phoneInputError(): string | void {    
    if (this.phoneInput.hasError('required')) { return Constants.message.requiredField; }
  }

  get emailInput(): any {return this.form.get('email'); }
  get emailInputError(): string | void{
    if (this.emailInput.hasError('email')) { return Constants.message.invalidEmail; }
    if (this.emailInput.hasError('required')) { return Constants.message.requiredField; }
  }

  get firstnameInput(): any {return this.form.get('firstname'); }
  get firstnameInputError(): string | void {    
    if (this.firstnameInput.hasError('required')) { return Constants.message.requiredField; }
  }

  get lastnameInput(): any {return this.form.get('lastname'); }
  get lastnameInputError(): string | void {    
    if (this.lastnameInput.hasError('required')) { return Constants.message.requiredField; }
  }

  get genderInput(): any {return this.form.get('gender'); }
  get genderInputError(): string | void {    
    if (this.firstnameInput.hasError('required')) { return Constants.message.requiredField; }
  }

  get titleInput(): any {return this.form.get('title'); }
  get titleInputError(): string | void {    
    if (this.firstnameInput.hasError('required')) { return Constants.message.requiredField; }
  }
  
}
