import { environment } from "src/environments/environment";
import { CLIENT_ID } from "./constant";

export const API_BASE = environment.apiBase;

export const ApiUrls = {
  ARTICLES: API_BASE  + CLIENT_ID + '/articles',
  ASSETS: API_BASE  + CLIENT_ID + '/assets',
  ATTRIBUTES: API_BASE  + CLIENT_ID + '/attribute',
  AUTH: API_BASE  + CLIENT_ID + '/users/authenticate',
  USERS: API_BASE  + CLIENT_ID + '/users',
  TASKS: API_BASE  + CLIENT_ID + '/tasks',
  CUSTOMERS: API_BASE  + CLIENT_ID + '/customers',
  NOTES: API_BASE  + CLIENT_ID + '/notes',
  TAX_CODES: API_BASE  + CLIENT_ID + '/taxcodes',
  PAYMENT_METHODS: API_BASE  + CLIENT_ID + '/paymentmethods',
  INVOICES: API_BASE  + CLIENT_ID + '/invoices',
  PAYMENTS: API_BASE  + CLIENT_ID + '/payments',
  COMPANIES: API_BASE  + CLIENT_ID + '/companies',
  SERVICES: API_BASE  + CLIENT_ID + '/services',
  PROFESSIONALS: API_BASE  + CLIENT_ID + '/professionals',
  OFFICES: API_BASE  + CLIENT_ID + '/offices',
  ADDRESS: API_BASE  + CLIENT_ID + '/address',
  EVENTS: API_BASE  + CLIENT_ID + '/events',
  CLIENT: API_BASE  + CLIENT_ID + '/' + CLIENT_ID,
  COMMENTS: API_BASE  + CLIENT_ID + '/comments',
  LOCATIONS: API_BASE  + CLIENT_ID + '/locations',
  PROPERTIES: API_BASE  + CLIENT_ID + '/properties',
  STOCKS: API_BASE  + CLIENT_ID + '/stocks',
  TRACKINGS: API_BASE  + CLIENT_ID + '/trackings',
  PING: API_BASE  + 'public/ping',
}