import { Component, Inject, OnInit } from '@angular/core';
import { FormGroup, FormControl, FormBuilder, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import * as moment from 'moment-timezone';
import { CompanyModel } from 'src/app/models/company';
import { EventModel } from 'src/app/models/event';
import { ProfessionalModel } from 'src/app/models/professional';
import { ServiceModel } from 'src/app/models/service';
import { EventService } from 'src/app/services/event.service';
import { CLIENT_ID, START_HOUR, WORK_HOURS } from 'src/app/config/constant';
import {MAT_MOMENT_DATE_FORMATS, MomentDateAdapter} from '@angular/material-moment-adapter';
import {DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE} from '@angular/material/core';
import { Page } from 'src/app/models/page';
import { HttpErrorResponse } from '@angular/common/http';
import { ToastService } from 'src/app/services/toast.service';
import { Constants } from 'src/app/config/constant';

export const MY_FORMATS = {
  parse: {
      dateInput: 'LL'
  },
  display: {
      dateInput: 'MM/DD/YYYY',
      monthYearLabel: 'YYYY',
      dateA11yLabel: 'LL',
      monthYearA11yLabel: 'YYYY'
  }
};

@Component({
  selector: 'app-edit-event-dlg',
  templateUrl: './edit-event-dlg.component.html',
  styleUrls: ['./edit-event-dlg.component.scss'],
  providers: [
    {provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE]},
    {provide: MAT_DATE_FORMATS, useValue: MY_FORMATS},
  ],
})
export class EditEventDlgComponent implements OnInit {
  form: FormGroup;
  title:string;
  bCreateMode:boolean;
  eventData:EventModel;
  services:ServiceModel[];
  professionals:ProfessionalModel[];
  serviceTimeList:string[]=[];
  error:string|null =null;

  loading = false;
  scheduledEvents:EventModel[] = [];
  displayedColumns=['time', 'customer'];

  constructor(
    private router: Router,
    private EventService: EventService, 
    private toastService: ToastService,
    private dialogRef: MatDialogRef<EditEventDlgComponent>,
    private fb: FormBuilder,
    @Inject(MAT_DIALOG_DATA) data:any
  ) { 
    this.services=data.services;
    this.professionals=data.professionals;
    this.eventData = {...data.event};

    const END_HOUR=(START_HOUR+WORK_HOURS)*60;

    for(let t=START_HOUR*60; t<END_HOUR; t+=30){
      const h=Math.floor(t/60);
      const hh=(h<10)?`0${h}`:h;
      const m=t%60;
      const mm=(m<10)?`0${m}`:m;
      this.serviceTimeList.push(
        `${hh}:${mm}`
      );
    }

    const currentTime = moment.parseZone();    
    const startTime = (this.eventData.startTime!='')?moment.parseZone(this.eventData.startTime).format('HH:mm'):'';
    const endTime = (this.eventData.endTime!='')?moment.parseZone(this.eventData.endTime).format('HH:mm'):'';
    const doctor = (this.eventData.professionalId>0)?this.eventData.professionalId:'';

    const serviceId = (this.eventData.eventServices.length>0)?this.eventData.eventServices[0].serviceId:'';
    this.form = this.fb.group({
      service: [serviceId, [Validators.required]],
      subject: [this.eventData.eventTitle, [Validators.required]],
      date: [currentTime, [Validators.required]],
      timeFrom: [startTime, [Validators.required]],
      timeTo: [endTime, [Validators.required]],
      professional: [doctor, [Validators.required]]
    });
    if(this.eventData.eventId===0){
      this.title='New Customer Appointment';
      this.bCreateMode=true;
    }
    else{
      this.bCreateMode=false;
      this.title='Edit Customer Appointment';
    }

    if(this.eventData.professionalId!=0 && this.eventData.startTime!=''){
      const from=moment.parseZone(this.eventData.startTime).format('YYYY-MM-DDT00:00:00');
      const to=moment.parseZone(this.eventData.startTime).format('YYYY-MM-DDT23:59:59');
      this.loadScheduledEvents(this.eventData.professionalId, from, to);
    }
  }

  ngOnInit(): void {
  }

  save(){
    if (this.form.valid) {
      const editedValue=this.form.value;

      if(editedValue.timeFrom>=editedValue.timeTo){
        this.error = 'End time must be after start time.';
        return;
      }

      this.eventData.eventServices=[{
        clientId	: 0,
        companyId	: 0,
        eventId	: 0,
        eventServiceId	: 0,
        serviceId	: editedValue.service,
        serviceLabel	: '',
        createdDate	: '',
        modifiedDate	: '',
        createdBy	: 0,
        modifiedBy	: 0
      }];

      let professionalIndex=-1;
      for(let i=0; i<this.professionals.length; i++){
        if(this.professionals[i].professionalId==editedValue.professional){
          professionalIndex=i;
          break;
        }
      }
      if(professionalIndex==-1){
        this.eventData.professionalId = 0;
      }
      else{
        this.eventData.professionalId = this.professionals[professionalIndex].professionalId;
        this.eventData.professionalName = this.professionals[professionalIndex].firstname+' '+this.professionals[professionalIndex].lastname;
      }
      
      this.eventData.eventTitle=editedValue.subject;

      const date=moment(editedValue.date).format('YYYY-MM-DD');
      const startTime = moment.tz(date+' '+editedValue.timeFrom+':00.000', 'America/Los_Angeles');
      const endTime = moment.tz(date+' '+editedValue.timeTo+':00.000', 'America/Los_Angeles');
      this.eventData.startTime = startTime.format('YYYY-MM-DDTHH:mm:ss');
      this.eventData.endTime = endTime.format('YYYY-MM-DDTHH:mm:ss');

      this.EventService.saveEvent(
        this.eventData,
        this.onSave,
        this.onNotSave
      );
    }
  }

  close() {
    this.dialogRef.close();
  }

  onSave=(pro:EventModel)=>{
    this.toastService.showSuccess(Constants.message.successSaved);
    this.dialogRef.close(pro);
  }

  onNotSave=(error: any) => {
    this.toastService.showResponseError(error);
    if(error.status == 401){
      this.router.navigate(['/auth/sign-in']);
    }
  }

  inputDate(){
    const editedValue=this.form.value;
    const date=moment(editedValue.date);
    if(date.isValid()){
      const professionalId=editedValue.professional;
      this.loadScheduledEvents(professionalId, date.format('YYYY-MM-DDT00:00:00'), date.format('YYYY-MM-DDT23:59:59'));
    }
  }

  changeProfessionalAndDate(){
    const editedValue=this.form.value;
    const from=moment(editedValue.date).format('YYYY-MM-DDT00:00:00');
    const to=moment(editedValue.date).format('YYYY-MM-DDT23:59:59');
    const professionalId=editedValue.professional;
    this.loadScheduledEvents(professionalId, from, to);
  }

  loadScheduledEvents(professionalId:number, from:string, to:string){
    this.scheduledEvents=[];

    if(professionalId==0)
      return;

    this.loading=true;
    this.EventService.getEventsByProfessionalAndDate(
      professionalId, from, to, undefined,
      (data: Page<EventModel>)=>{
        this.loading=false;
        this.scheduledEvents=data.content;
      },
      (status:number, error:HttpErrorResponse)=>{
        this.loading=false;

      }
    )
  }

  get serviceInput(): any {return this.form.get('service'); }
  get serviceInputError(): string | void {    
    if (this.serviceInput.hasError('required')) { return Constants.message.requiredField; }
  }
  get subjectInput(): any {return this.form.get('subject'); }
  get subjectInputError(): string | void {    
    if (this.subjectInput.hasError('required')) { return Constants.message.requiredField; }
  }
  get dateInput(): any {return this.form.get('date'); }
  get dateInputError(): string | void {    
    if (this.dateInput.hasError('required')) { return Constants.message.requiredField; }
  }
  get timeFromInput(): any {return this.form.get('timeFrom'); }
  get timeFromInputError(): string | void {    
    if (this.timeFromInput.hasError('required')) { return Constants.message.requiredField; }
  }
  get timeToInput(): any {return this.form.get('timeTo'); }
  get timeToInputError(): string | void {    
    if (this.timeToInput.hasError('required')) { return Constants.message.requiredField; }
  }
  get professionalInput(): any {return this.form.get('professional'); }
  get professionalInputError(): string | void {    
    if (this.professionalInput.hasError('required')) { return Constants.message.requiredField; }
  }
}
