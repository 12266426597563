<h2 mat-dialog-title>{{title}}</h2>
<form  [formGroup]="form" (ngSubmit)="save()">
    <mat-dialog-content>
        <mat-form-field appearance="standard" class="w-100">
            <mat-label>Address Label</mat-label>
            <input matInput
                    type="text"
                    placeholder="Address Label"
                    required
                formControlName="label">
            <mat-error *ngIf="labelInput.invalid">{{labelInputError}}</mat-error>
        </mat-form-field>
        <mat-form-field class="w-100">
            <input matInput
                    type="text"
                    placeholder="Address 1"
                    required
                formControlName="address1">
            <mat-error *ngIf="address1Input.invalid">{{address1InputError}}</mat-error>
        </mat-form-field>
        <mat-form-field class="w-100">
            <input matInput
                    type="text"
                    placeholder="Address 2"
                formControlName="address2">
        </mat-form-field>
        <mat-form-field class="w-100">
            <input matInput
                    type="text"
                    required
                    placeholder="City/Suburb"
                formControlName="city">
            <mat-error *ngIf="cityInput.invalid">{{cityInputError}}</mat-error>
        </mat-form-field>
        <mat-form-field class="w-100">
            <input matInput
                    type="text"
                    required
                    placeholder="Postcode"
                formControlName="postcode">
            <mat-error *ngIf="postcodeInput.invalid">{{postcodeInputError}}</mat-error>
        </mat-form-field>
        <mat-form-field class="w-100">
            <input matInput
                    type="text"
                    required
                    placeholder="State"
                formControlName="state">
            <mat-error *ngIf="stateInput.invalid">{{stateInputError}}</mat-error>
        </mat-form-field>
        <mat-form-field class="w-100">
            <mat-select
                    required
                    placeholder="Country"
                formControlName="countryCode">                
                <mat-option value="CA">Canada</mat-option>
                <mat-option value="US">United States</mat-option>
            </mat-select>
            <mat-error *ngIf="countryCodeInput.invalid">{{countryCodeInputError}}</mat-error>
        </mat-form-field>
        <div class="form-control">
            <mat-checkbox formControlName="isBilling">Is Billing</mat-checkbox>
        </div>
        <div class="form-control">
            <mat-checkbox formControlName="isShipping">Is Shipping</mat-checkbox>
        </div>
        <input type="hidden" formControlName="addressId" />
        <input type="hidden" formControlName="category" />
        <input type="hidden" formControlName="clientId" />
        <input type="hidden" formControlName="code" />
        <input type="hidden" formControlName="referenceId" />
        <input type="hidden" formControlName="createdDate" />
        <input type="hidden" formControlName="modifiedDate" />
        <input type="hidden" formControlName="createdBy" />
        <input type="hidden" formControlName="modifiedBy" />        
    </mat-dialog-content>
    <mat-divider></mat-divider>
    <mat-dialog-actions align="end">
        <button type="button" mat-raised-button mat-dialog-close color="warn">Close</button> 
        <button type="submit" mat-raised-button color="accent">Save</button>        
    </mat-dialog-actions>
</form>