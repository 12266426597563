import { Component, Input, OnInit } from '@angular/core';
import { FormGroup, FormControl, FormBuilder, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { UserService } from 'src/app/services/user.service';
import { ToastService } from 'src/app/services/toast.service';
import { Constants } from 'src/app/config/constant';
import { CrossFieldErrorMatcher } from 'src/app/services/public.service';

@Component({
  selector: 'app-eidt-password-dlg',
  templateUrl: './eidt-password-dlg.component.html',
  styleUrls: ['./eidt-password-dlg.component.scss']
})
export class EidtPasswordDlgComponent implements OnInit {
  form: FormGroup;
  errorMatcher = new CrossFieldErrorMatcher();

  @Input() error: string | null | undefined;

  constructor(
    private fb: FormBuilder,
    private router: Router,
    private userService: UserService,
    private dialogRef: MatDialogRef<EidtPasswordDlgComponent>,
    private toastService: ToastService,
  ) {
    this.form = this.fb.group({
      oldPassword: ['', Validators.required],
      newPassword: ['', [Validators.required, Validators.minLength(Constants.password.minLength), Validators.maxLength(Constants.password.maxLength)]],
      confirm: ['', Validators.required],
    },{
      validator: this.passwordValidator
    })
  }

  ngOnInit(): void {
    this.error = ''
  }

  save() {
    if (this.form.valid) {      
      this.userService.changePassword(
        this.form.value.oldPassword,
        this.form.value.newPassword,
        this.onChange,
        this.onNotChange,
      )
    }
  }

  onChange = () => {
    this.toastService.showSuccess(Constants.message.successSaved);
    this.close()
  }

  onNotChange = (error:any) => {
    this.toastService.showResponseError(error);
    if(error.status == 401){
      this.router.navigate(['/auth/sign-in']);
    }
  }

  close() {
    this.dialogRef.close()
  }

  passwordValidator(form: FormGroup) {
    const condition = form.get('newPassword')?.value !== form.get('confirm')?.value;

    return condition ? { passwordsDoNotMatch: true} : null;
  }

  get newPasswordInput(): any {return this.form.get('newPassword'); }
  get newPasswordInputError(): string | void {
    if (this.newPasswordInput.hasError('required')) { return Constants.message.requiredField; }
    if (this.newPasswordInput.hasError('minlength')) { return Constants.message.minLengthPwd.replace('?', Constants.password.minLength.toString()); }
    if (this.newPasswordInput.hasError('maxlength')) { return Constants.message.maxLengthPwd.replace('?', Constants.password.maxLength.toString()); }    
  }

  get oldPasswordInput(): any {return this.form.get('oldPassword'); }
  get oldPasswordInputError(): string | void {    
    if (this.oldPasswordInput.hasError('required')) { return Constants.message.requiredField; }
  }

  get confirmInput(): any {return this.form.get('confirm'); }
  get confirmInputError(): string | void {    
    if (this.confirmInput.hasError('required')) { return Constants.message.requiredField; }
  }
}
