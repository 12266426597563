import { HttpParams, HttpParamsOptions } from "@angular/common/http";

export interface QueryParams{
  query?:string;
  page?:number;
  size?:number;
  sort?:{
    field: string;
    order: string
  };

  date?: string
};

export function createHttpParams(queryParams?:QueryParams):HttpParams{
  let httpParams =  new HttpParams();
  if(queryParams){
    if(queryParams.query !== undefined)
      httpParams =  httpParams.set('query', queryParams.query);
    if(queryParams.page !== undefined)
      httpParams =  httpParams.set('page', queryParams.page.toString());
    if(queryParams.size !== undefined){
      httpParams =  httpParams.set('size', queryParams.size.toString());
    }
    if(queryParams.sort !== undefined){
      httpParams =  httpParams.set('sort', `${queryParams.sort.field},${queryParams.sort.order}`);
    }
    if(queryParams.date !== undefined){
      httpParams =  httpParams.set('date', queryParams.date);
    }
  }
  
  return httpParams;
}