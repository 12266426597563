import { HttpClient, HttpErrorResponse, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApiUrls } from '../config/api.endpoints';

import { CompanyModel } from '../models/company';
import { Page } from '../models/page';
import { QueryParams, createHttpParams } from '../models/query-params';
import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root'
})

export class CompanyService {
  static COMPANY_DATA_KEY= "FLN_COMPANY_DATA";
  companyData:CompanyModel|null=null;
  companyList:CompanyModel[] = [];

  constructor(
    private http: HttpClient,
    private authService: AuthService
  ) {
    this.readCompanyData();
  }

  getCompany(companyId:number, onSuccess:any, onError?:any){
    
    const httpOptions = {
      headers: new HttpHeaders({
        'x-auth-token': this.authService.token
      }),
    };
    this.http.get<CompanyModel>(ApiUrls.COMPANIES+'/'+companyId, httpOptions)
      .subscribe((data)=>{
        if(onSuccess)
          onSuccess(data);
      }, (error:HttpErrorResponse)=>{
        if(onError)
          onError(error.status);
      });
  }
  
  getCompanyList(
    queryParams?: QueryParams,
    onSuccess?:any, onError?:any
  ){
    
    const httpOptions = {
      params: createHttpParams(queryParams),
      headers: new HttpHeaders({
        'x-auth-token': this.authService.token
      }),
    };

    this.http.get<Page<CompanyModel>>(ApiUrls.COMPANIES, httpOptions)
      .subscribe((data)=>{
        if(onSuccess)
          onSuccess(data);
      }, (error:HttpErrorResponse)=>{
        if(onError)
          onError(error);
      });
  }

  deleteCompany(
    companyId:number, 
    onSuccess?:any, onError?:any){
    const httpOptions = {
      headers: new HttpHeaders({
        'x-auth-token': this.authService.token
      }),
    };

    this.http.delete<void>(ApiUrls.COMPANIES+'/'+companyId, httpOptions)
      .subscribe(()=>{
        if(onSuccess)
          onSuccess();
      }, (error:HttpErrorResponse)=>{
        if(onError)
          onError(error);
      });
  }

  undeleteCompany(
    companyId:number, 
    onSuccess?:any, onError?:any){
    const httpOptions = {
      headers: new HttpHeaders({
        'x-auth-token': this.authService.token
      }),
    };

    this.http.get<void>(ApiUrls.COMPANIES+'/recycle/'+companyId, httpOptions)
      .subscribe(()=>{
        if(onSuccess)
          onSuccess();
      }, (error:HttpErrorResponse)=>{
        if(onError)
          onError(error);
      });
  }

  saveCompany(
    company:CompanyModel,
    onSuccess?:any, onError?:any
  ){
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'x-auth-token': this.authService.token
      }),
    };

    this.http.post<CompanyModel>(ApiUrls.COMPANIES, company, httpOptions)
      .subscribe((data)=>{
        if(onSuccess)
          onSuccess(data);
      }, (error:HttpErrorResponse)=>{
        if(onError)
          onError(error);
      });
  }

  readCompanyData(){
    const strCompanyData=localStorage.getItem(CompanyService.COMPANY_DATA_KEY);
    if(strCompanyData){
      this.companyData=JSON.parse(strCompanyData);
    }
  }

  saveCompanyData(){
    if(this.companyData)
      localStorage.setItem(CompanyService.COMPANY_DATA_KEY, JSON.stringify(this.companyData));
  }

  unsetCompanyData=()=>{
    this.companyData=null;
    localStorage.removeItem(CompanyService.COMPANY_DATA_KEY);
  }

  setMyCompany(company:CompanyModel){
    this.companyData=company;
    this.saveCompanyData();
  }
}
