import { HttpErrorResponse } from '@angular/common/http';
import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { Page } from 'src/app/models/page';
import { ProfessionalModel } from 'src/app/models/professional';
import { UserAccessModel } from 'src/app/models/user.access';
import { UserAccessUpdateModel } from 'src/app/models/user.access.update';
import { ProfessionalService } from 'src/app/services/professional.service';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-edit-user-access-dlg',
  templateUrl: './edit-user-access-dlg.component.html',
  styleUrls: ['./edit-user-access-dlg.component.scss']
})
export class EditUserAccessDlgComponent implements OnInit {
  proList:EditUserAccessModel[]=[];
  orgAccesses:UserAccessModel[]=[];
  username:string;
  error:string='';
  bLoading = true;

  constructor(
    private router: Router,
    private proService: ProfessionalService,
    private userService: UserService,
    private dialogRef: MatDialogRef<EditUserAccessDlgComponent>,
    @Inject(MAT_DIALOG_DATA) username:any
  ) {
    this.username=username;
    this.proService.getProfessionalList(
      undefined,
      this.onGetPros);
  }

  ngOnInit(): void {
  }

  onGetPros=(pros:Page<ProfessionalModel>)=>{
    this.userService.getUserAccess(this.username,
      (accesses:UserAccessModel[])=>{
        this.bLoading = false;
        this.orgAccesses=accesses;
        pros.content.forEach((pro) => {
          const aid=this.findAccess(pro.professionalId);
          const bac=(aid!==0);
          this.proList.push({
            professional: pro,
            access: bac,
            oldAccess: bac,
            accessId:aid
          });
        });
        
      },
      (error:HttpErrorResponse)=>{
        this.bLoading = false;
        this.error = "Cannot get access list";
      }
    );
  }

  findAccess=(proId:number)=>{
    const len=this.orgAccesses.length;
    for(let i=0; i<len; i++){
      if(this.orgAccesses[i].professionalId===proId)
        return this.orgAccesses[i].userAccessId;
    }
    return 0;
  }

  close() {
    this.dialogRef.close();
  }

  save=()=>{
    const updateData:UserAccessUpdateModel={
      added: [],
      removed: []
    };
    const len=this.proList.length;
    for(let i=0; i<len; i++){
      const pro=this.proList[i];
      if(pro.access!==pro.oldAccess){
        const newAccess={
          clientId:1,
          professionalFirstname:'',
          professionalLastname:'',
          professionalId:pro.professional.professionalId,
          userAccessId:pro.accessId,
          userId:0,
          createdBy:0,
          createdDate:'',
          modifiedBy:0,
          modifiedDate:''
        };

        if(pro.access){
          updateData.added.push(newAccess);
        }
        else{
          updateData.removed.push(newAccess);
        }
      }
    }
    console.log(updateData);
    if(updateData.added.length+updateData.removed.length===0){
      this.error='Not changed.';
    }
    else{
      this.userService.addUserAccess(
        this.username, updateData,
        this.onSave,
        this.onNotSave);
    }
  }

  onSave=()=>{
    this.showMessage('Alert', 'Updated user access.');
    this.close();
  }

  onNotSave=(status: number) => {
    if (status === 401) {
      this.showMessage('Error', "You haven't access permission.");
    } else if (status === 403) {
      this.showMessage('Error', 'Your access request is rejected.');
    } else if (status === 404) {
      this.showMessage('Error', 'Cannot find server.');
    } else {
      this.showMessage('Error', "Unknown error was occured.");
    }
  }

  showMessage(title:string, text:string, subtitle?:string){
    //TODO: show toast message
  }
}

interface EditUserAccessModel{
  professional: ProfessionalModel;
  access: boolean;
  oldAccess:boolean;
  accessId:number;
}