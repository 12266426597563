import { Component, Inject, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators  } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { PaymentMethodModel } from 'src/app/models/payment.method';
import { PaymentMethodService } from 'src/app/services/payment-method.service';
import { ToastService } from 'src/app/services/toast.service';
import { Constants } from 'src/app/config/constant';

@Component({
  selector: 'app-edit-payment-method-dlg',
  templateUrl: './edit-payment-method-dlg.component.html',
  styleUrls: ['./edit-payment-method-dlg.component.scss']
})
export class EditPaymentMethodDlgComponent implements OnInit {
  form: FormGroup;
  title:string;
  bCreateMode:boolean;

  constructor(
    private router: Router,
    private payService: PaymentMethodService, 
    private dialogRef: MatDialogRef<EditPaymentMethodDlgComponent>,
    private fb: FormBuilder,
    private toastService: ToastService,
    @Inject(MAT_DIALOG_DATA) data:PaymentMethodModel
  ) { 
    this.form = this.fb.group({
      clientId: [data.clientId],
      paymentMethodCode: [data.paymentMethodCode, [Validators.required]],
      paymentMethodId: [data.paymentMethodId],
      paymentMethodLabel: [data.paymentMethodLabel, [Validators.required]],
      createdDate: [data.createdDate],
      modifiedDate: [data.modifiedDate],
      createdBy: [data.createdBy],
      modifiedBy: [data.modifiedBy]
    });
    if(data.paymentMethodId===0){
      this.title='Add Payment Method';
      this.bCreateMode=true;
    }
    else{
      this.bCreateMode=false;
      this.title='Edit Payment Method';
    }
  }

  ngOnInit(): void {
  }

  save(){
    if (this.form.valid) {
      this.payService.savePaymentMethod(
        this.form.value,
        this.onSave,
        this.onNotSave
      );
    }
  }

  close() {
    this.dialogRef.close();
  }

  onSave=(pro:PaymentMethodModel)=>{
    this.toastService.showSuccess(Constants.message.successSaved);
    this.dialogRef.close(pro);
  }

  onNotSave=(error: any) => {
    this.toastService.showResponseError(error);
    if(error.status == 401){
      this.router.navigate(['/auth/sign-in']);
    }
  }

  get paymentMethodCodeInput(): any {return this.form.get('paymentMethodCode'); }
  get paymentMethodCodeInputError(): string | void {    
    if (this.paymentMethodCodeInput.hasError('required')) { return Constants.message.requiredField; }
  }
  get paymentMethodLabelInput(): any {return this.form.get('paymentMethodLabel'); }
  get paymentMethodLabelInputError(): string | void {    
    if (this.paymentMethodLabelInput.hasError('required')) { return Constants.message.requiredField; }
  }
}
