import { Component, Inject, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { CompanyModel } from 'src/app/models/company';
import { CompanyService } from 'src/app/services/company.service';
import { ToastService } from 'src/app/services/toast.service';
import { Constants } from 'src/app/config/constant';

@Component({
  selector: 'app-edit-company-dlg',
  templateUrl: './edit-company-dlg.component.html',
  styleUrls: ['./edit-company-dlg.component.scss']
})
export class EditCompanyDlgComponent implements OnInit {
  form: FormGroup;
  title:string;
  bCreateMode:boolean;

  constructor(
    private router: Router,
    private companyService: CompanyService, 
    private dialogRef: MatDialogRef<EditCompanyDlgComponent>,
    private fb: FormBuilder,
    private toastService: ToastService,
    @Inject(MAT_DIALOG_DATA) data:CompanyModel
  ) { 
    this.form = this.fb.group({
      clientId: [data.clientId],
      companyId: [data.companyId],
      code: [data.code, [Validators.required]],
      name: [data.name, [Validators.required]],
      email: [data.email, [Validators.required, Validators.email]],
      invoicePrefix: [data.invoicePrefix],
      workPhone: [data.workPhone],
      currencyCode: [data.currencyCode, [Validators.required]],
      createdDate: [data.createdDate],
      modifiedDate: [data.modifiedDate],
      createdBy: [data.createdBy],
      modifiedBy: [data.modifiedBy],
    });
    if(data.companyId===0){
      this.title='Add Company';
      this.bCreateMode=true;
    }
    else{
      this.bCreateMode=false;
      this.title='Edit Company';
    }
  }

  ngOnInit(): void {
  }

  save(){
    if (this.form.valid) {
      this.companyService.saveCompany(
        this.form.value,
        this.onSave,
        this.onNotSave
      );
    }
  }

  close() {
    this.dialogRef.close();
  }

  onSave=(pro:CompanyModel)=>{
    this.toastService.showSuccess(Constants.message.successSaved);
    this.dialogRef.close(pro);
  }

  onNotSave=(error: any) => {
    this.toastService.showResponseError(error);
    if(error.status == 401){
      this.router.navigate(['/auth/sign-in']);
    }
  }

  get codeInput(): any {return this.form.get('code'); }
  get codeInputError(): string | void {    
    if (this.codeInput.hasError('required')) { return Constants.message.requiredField; }
  }

  get emailInput(): any {return this.form.get('email'); }
  get emailInputError(): string | void{
    if (this.emailInput.hasError('email')) { return Constants.message.invalidEmail; }
    if (this.emailInput.hasError('required')) { return Constants.message.requiredField; }
  }

  get nameInput(): any {return this.form.get('name'); }
  get nameInputError(): string | void {    
    if (this.nameInput.hasError('required')) { return Constants.message.requiredField; }
  }

  get currencyCodeInput(): any {return this.form.get('currencyCode'); }
  get currencyCodeInputError(): string | void {    
    if (this.currencyCodeInput.hasError('required')) { return Constants.message.requiredField; }
  }
}
