import { Component, Inject, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { OfficeModel } from 'src/app/models/office';
import { OfficeService } from 'src/app/services/office.service';
import { ToastService } from 'src/app/services/toast.service';
import { Constants } from 'src/app/config/constant';

@Component({
  selector: 'app-edit-office-dlg',
  templateUrl: './edit-office-dlg.component.html',
  styleUrls: ['./edit-office-dlg.component.scss']
})
export class EditOfficeDlgComponent implements OnInit {
  form: FormGroup;
  title:string;
  bCreateMode:boolean;

  constructor(
    private router: Router,
    private proService: OfficeService, 
    private dialogRef: MatDialogRef<EditOfficeDlgComponent>,
    private fb: FormBuilder,
    private toastService: ToastService,
    @Inject(MAT_DIALOG_DATA) data:OfficeModel
  ) { 
    this.form = this.fb.group({
      clientId: [data.clientId],
      companyId: [data.companyId],
      companyName: [data.companyName],
      name: [data.name, [Validators.required]],
      officeId: [data.officeId],
      createdDate: [data.createdDate],
      modifiedDate: [data.modifiedDate],
      createdBy: [data.createdBy],
      modifiedBy: [data.modifiedBy],
    });
    if(data.officeId===0){
      this.title='Add Office';
      this.bCreateMode=true;
    }
    else{
      this.bCreateMode=false;
      this.title='Edit Office';
    }
  }

  ngOnInit(): void {
  }

  save(){
    if (this.form.valid) {
      this.proService.saveOffice(
        this.form.value,
        this.onSave,
        this.onNotSave
      );
    }
  }

  close() {
    this.dialogRef.close();
  }

  onSave=(pro:OfficeModel)=>{
    this.toastService.showSuccess(Constants.message.successSaved);
    this.dialogRef.close(pro);
  }

  onNotSave=(error: any) => {
    this.toastService.showResponseError(error);
    if(error.status == 401){
      this.router.navigate(['/auth/sign-in']);
    }
  }

  get nameInput(): any {return this.form.get('name'); }
  get nameInputError(): string | void {    
    if (this.nameInput.hasError('required')) { return Constants.message.requiredField; }
  }
}
