import { Component, Inject, Input, OnInit } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import {
  FormControl,
  FormGroup,
  FormBuilder,
  Validators,
} from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { AddressModel } from 'src/app/models/address';
import { AddressDlgModel } from 'src/app/models/address.dlg';
import { AddressService } from 'src/app/services/address.service';
import { ToastService } from 'src/app/services/toast.service';
import { Constants } from 'src/app/config/constant';

@Component({
  selector: 'app-eidt-address-dlg',
  templateUrl: './eidt-address-dlg.component.html',
  styleUrls: ['./eidt-address-dlg.component.scss'],
})
export class EidtAddressDlgComponent implements OnInit {
  form: FormGroup;
  title: string;
  refTable: string;
  userId: number;
  bCreateMode: boolean;
  @Input() error: string | null | undefined;

  constructor(
    private router: Router,
    private addressService: AddressService,
    private toastService: ToastService,
    private fb: FormBuilder,
    private dialogRef: MatDialogRef<EidtAddressDlgComponent>,
    @Inject(MAT_DIALOG_DATA) data: AddressDlgModel
  ) {
    this.form = this.fb.group({
      label: [data.label, [Validators.required]],
      address1: [data.address1, [Validators.required]],
      address2: [data.address2],
      city: [data.city, [Validators.required]],
      postcode: [data.postcode, [Validators.required]],
      state: [data.state, [Validators.required]],
      countryCode: [data.countryCode, [Validators.required]],
      isBilling: [data.isBilling],
      isShipping: [data.isBilling],
      addressId: [data.addressId],
      category: [data.category],
      clientId: [data.clientId],
      // the code is sending 1 every time, so it can not be added to backend. As we are sending the addressId, I'm not sure
      // if we need this code or not. SO, now by commented out this line, post req is working fine.
      // code: [data.code],
      referenceId: [data.referenceId],
      createdDate: [data.createdDate],
      modifiedDate: [data.modifiedDate],
      createdBy: [data.createdBy],
      modifiedBy: [data.userId],
    });
    if (data.addressId === 0) {
      this.title = 'New Customer Address';
      this.bCreateMode = true;
    } else {
      this.bCreateMode = false;
      this.title = 'Edit Customer Address';
    }
    this.refTable = data.refTable;
    this.userId = data.userId;
  }

  ngOnInit(): void {}

  save() {
    if (this.form.valid) {
      console.log('save-edit-address-dlg', this.form.value);

      this.addressService.createOrUpdateAddress(
        this.refTable,
        this.userId,
        this.form.value,
        this.onSave,
        this.onNotSave
      );
    }
  }

  close() {
    this.dialogRef.close();
  }

  onSave = (address: AddressModel) => {
    this.toastService.showSuccess(Constants.message.successSaved);
    this.dialogRef.close(address);
  };

  onNotSave = (error: any) => {
    this.toastService.showResponseError(error);
    if (error.status == 401) {
      this.router.navigate(['/auth/sign-in']);
    }
  };

  get labelInput(): any {
    return this.form.get('label');
  }
  get labelInputError(): string | void {
    if (this.labelInput.hasError('required')) {
      return Constants.message.requiredField;
    }
  }

  get address1Input(): any {
    return this.form.get('address1');
  }
  get address1InputError(): string | void {
    if (this.address1Input.hasError('required')) {
      return Constants.message.requiredField;
    }
  }

  get cityInput(): any {
    return this.form.get('city');
  }
  get cityInputError(): string | void {
    if (this.cityInput.hasError('required')) {
      return Constants.message.requiredField;
    }
  }

  get postcodeInput(): any {
    return this.form.get('postcode');
  }
  get postcodeInputError(): string | void {
    if (this.postcodeInput.hasError('required')) {
      return Constants.message.requiredField;
    }
  }

  get stateInput(): any {
    return this.form.get('state');
  }
  get stateInputError(): string | void {
    if (this.stateInput.hasError('required')) {
      return Constants.message.requiredField;
    }
  }

  get countryCodeInput(): any {
    return this.form.get('countryCode');
  }
  get countryCodeInputError(): string | void {
    if (this.countryCodeInput.hasError('required')) {
      return Constants.message.requiredField;
    }
  }
}
