<h2 mat-dialog-title>{{title}}</h2>
<form [formGroup]="form" (ngSubmit)="save()">
  <mat-dialog-content>
      <mat-form-field appearance="standard" class="w-100">
          <mat-label>Company Name</mat-label>
          <input matInput
                  type="text"
                  placeholder="Company Name..."
                formControlName="name" required>
          <mat-error *ngIf="nameInput.invalid">{{nameInputError}}</mat-error>
      </mat-form-field>
      <mat-form-field class="w-100">
        <mat-label>Company Code</mat-label>
        <input matInput
                type="text"
                placeholder="CODE"
              formControlName="code" required>
        <mat-error *ngIf="codeInput.invalid">{{codeInputError}}</mat-error>
      </mat-form-field>
      <mat-form-field class="w-100">
        <mat-label>Work Phone</mat-label>
        <input matInput
                type="text"
                placeholder="Phone number"
              formControlName="workPhone">
      </mat-form-field>
      <mat-form-field class="w-100">
        <mat-label>Email</mat-label>
        <input matInput
                type="text"
                placeholder="name@example.com"
              formControlName="email" required>
        <mat-error *ngIf="emailInput.invalid">{{emailInputError}}</mat-error>
      </mat-form-field>
      <mat-form-field class="w-100">
        <mat-label>Currency</mat-label>
        <mat-select
                placeholder="Select Currency Type"
              formControlName="currencyCode" required>              
              <mat-option value="USD">USD</mat-option>
              <mat-option value="CAD">CAD</mat-option>
        </mat-select>
        <mat-error *ngIf="currencyCodeInput.invalid">{{currencyCodeInputError}}</mat-error>
    </mat-form-field>
      <input type="hidden" formControlName="clientId" />
      <input type="hidden" formControlName="companyId" />
      <input type="hidden" formControlName="invoicePrefix" />
      <input type="hidden" formControlName="createdDate" />
      <input type="hidden" formControlName="modifiedDate" />
      <input type="hidden" formControlName="createdBy" />
      <input type="hidden" formControlName="modifiedBy" />
  </mat-dialog-content>
  <mat-divider></mat-divider>
  <mat-dialog-actions align="end">
    <button type="button" mat-raised-button mat-dialog-close color="warn">Close</button> 
    <button type="submit" mat-raised-button color="accent">Save</button>
  </mat-dialog-actions>
</form>