import { Component, Inject, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { UserService } from 'src/app/services/user.service';
import { ToastService } from 'src/app/services/toast.service';
import { Constants } from 'src/app/config/constant';
import { CrossFieldErrorMatcher } from 'src/app/services/public.service';

@Component({
  selector: 'app-reset-password-dlg',
  templateUrl: './reset-password-dlg.component.html',
  styleUrls: ['./reset-password-dlg.component.scss']
})
export class ResetPasswordDlgComponent implements OnInit {
  form: FormGroup;
  username:string;
  errorMatcher = new CrossFieldErrorMatcher();

  @Input() error: string | null | undefined;
  constructor(
    private router: Router,
    private userService: UserService,
    private fb: FormBuilder,
    private toastService: ToastService,
    private dialogRef: MatDialogRef<ResetPasswordDlgComponent>,
    @Inject(MAT_DIALOG_DATA) username:string
  ) { 
    this.form = this.fb.group({      
      newPassword: ['', [Validators.required, Validators.minLength(Constants.password.minLength), Validators.maxLength(Constants.password.maxLength)]],
      confirm: ['', Validators.required],
    },{
      validator: this.passwordValidator
    })
    this.username=username;
  }

  ngOnInit(): void {
  }

  save() {
    if (this.form.valid) {
      if (this.form.value.newPassword === this.form.value.newPassword1) {
        this.userService.resetPassword(
          this.username,
          this.form.value.oldPassword,
          this.form.value.newPassword,
          this.onChange,
          this.onNotSave,
        )
      }
      else{
        this.error='Incorrect confirm password.';
      }
    }
  }

  onChange = () => {
    this.error = 'Your password was changed.'
    this.close()
  }

  onNotSave = (error:any) => {
    this.toastService.showResponseError(error);
    if(error.status == 401){
      this.router.navigate(['/auth/sign-in']);
    }
  }

  close() {
    this.dialogRef.close()
  }

  
  passwordValidator(form: FormGroup) {
    const condition = form.get('newPassword')?.value !== form.get('confirm')?.value;

    return condition ? { passwordsDoNotMatch: true} : null;
  }

  get newPasswordInput(): any {return this.form.get('newPassword'); }
  get newPasswordInputError(): string | void {
    if (this.newPasswordInput.hasError('required')) { return Constants.message.requiredField; }
    if (this.newPasswordInput.hasError('minlength')) { return Constants.message.minLengthPwd.replace('?', Constants.password.minLength.toString()); }
    if (this.newPasswordInput.hasError('maxlength')) { return Constants.message.maxLengthPwd.replace('?', Constants.password.maxLength.toString()); }    
  }

  get confirmInput(): any {return this.form.get('confirm'); }
  get confirmInputError(): string | void {    
    if (this.confirmInput.hasError('required')) { return Constants.message.requiredField; }
  }
}
