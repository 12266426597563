<h2 mat-dialog-title>{{title}}</h2>
<form [formGroup]="form" (ngSubmit)="save()">
    <mat-dialog-content>
        <mat-form-field class="w-100">
            <mat-label>Payment Method Code</mat-label>
            <input matInput
                    type="text"
                    placeholder="CODE"
                    required
                formControlName="paymentMethodCode">
            <mat-error *ngIf="paymentMethodCodeInput.invalid">{{paymentMethodCodeInputError}}</mat-error>
        </mat-form-field>
        <mat-form-field class="w-100">
            <mat-label>Payment Method Label</mat-label>
            <input matInput
                    type="text"
                    placeholder="Label"
                    required
                formControlName="paymentMethodLabel">
            <mat-error *ngIf="paymentMethodLabelInput.invalid">{{paymentMethodLabelInputError}}</mat-error>
        </mat-form-field>
        <input type="hidden" formControlName="clientId" />
        <input type="hidden" formControlName="paymentMethodId" />
        <input type="hidden" formControlName="createdDate" />
        <input type="hidden" formControlName="modifiedDate" />
        <input type="hidden" formControlName="createdBy" />
        <input type="hidden" formControlName="modifiedBy" />
    </mat-dialog-content>
    <mat-divider></mat-divider>
    <mat-dialog-actions align="end">
        <button type="button" mat-raised-button mat-dialog-close color="warn">Close</button> 
        <button type="submit" mat-raised-button color="accent">Save</button>
    </mat-dialog-actions>
</form>