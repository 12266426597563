<h2 mat-dialog-title>{{title}}</h2>
<form [formGroup]="form" (ngSubmit)="save()">
    <mat-dialog-content>
        <mat-form-field class="w-100">
            <mat-label>First Name</mat-label>
            <input matInput
                    type="text"
                    placeholder="First Name"
                    required
                formControlName="firstname">
            <mat-error *ngIf="firstnameInput.invalid">{{firstnameInputError}}</mat-error>
        </mat-form-field>
        <mat-form-field class="w-100">
            <mat-label>Last Name</mat-label>
            <input matInput
                    type="text"
                    placeholder="Last Name"
                    required
                formControlName="lastname">
            <mat-error *ngIf="lastnameInput.invalid">{{lastnameInputError}}</mat-error>
        </mat-form-field>
        <mat-form-field class="w-100">
            <mat-label>Phone Number</mat-label>
            <input matInput
                    type="text"
                    placeholder="Phone Number"
                formControlName="phoneNo">
        </mat-form-field>
        <mat-form-field class="w-100">
            <mat-label>Email</mat-label>
            <input matInput
                    type="email"
                    required
                    placeholder="Email"
                formControlName="email">
            <mat-error *ngIf="emailInput.invalid">{{emailInputError}}</mat-error>
        </mat-form-field>
        <mat-form-field class="w-100">
            <mat-label>Language</mat-label>
            <mat-select
                    placeholder="Language"
                formControlName="language">
                <mat-option value=""> </mat-option>
                <mat-option value="en-US">English-US</mat-option>
                <mat-option value="en-CA">English-CA</mat-option>
            </mat-select>
        </mat-form-field>
        <input type="hidden" formControlName="clientId" />
        <input type="hidden" formControlName="professionalId" />
        <input type="hidden" formControlName="status" />
        <input type="hidden" formControlName="createdDate" />
        <input type="hidden" formControlName="modifiedDate" />
        <input type="hidden" formControlName="createdBy" />
        <input type="hidden" formControlName="modifiedBy" />
    </mat-dialog-content>
    <mat-divider></mat-divider>
    <mat-dialog-actions align="end">
        <button type="button" mat-raised-button mat-dialog-close color="warn">Close</button> 
        <button type="submit" mat-raised-button color="accent">Save</button>
    </mat-dialog-actions>
</form>