<mat-toolbar color="primary" class="header-bar" ngClass.lt-sm="mobile">
  <button mat-button (click)="fireChangeCompany()" class="company-button">{{company}}</button>
  <a mat-button routerLink="/dashboard" fxHide.lt-sm routerLinkActive="active" >Dashboard</a>
  <a mat-button routerLink="/customers" fxHide.lt-sm routerLinkActive="active">Customers</a>
  <a mat-button routerLink="/appointments" fxHide.lt-sm routerLinkActive="active">Appointments</a>
  <span class="example-spacer"></span>
  <div class="right-menu" fxHide.lt-sm>
    <button mat-button [matMenuTriggerFor]="userMenu" class="user-button">
      <mat-icon>account_circle</mat-icon>{{username}}
    </button>
    <mat-menu #userMenu="matMenu" class="user-menu">
      <a mat-menu-item routerLink="/profile" routerLinkActive="active">
        <mat-icon>article</mat-icon>
        <span>Profile</span>
      </a>
      <a mat-menu-item routerLink="/setting" routerLinkActive="active">
        <mat-icon>settings</mat-icon>
        <span>Settings</span>
      </a>
      <mat-divider></mat-divider>
      <button mat-menu-item (click)="signOut()">
        <mat-icon>exit_to_app</mat-icon>
        <span>Log out</span>
      </button>
    </mat-menu>
  </div>

  <div class="toggle-side-menu" fxHide.gt-xs>
    <button mat-icon-button class="menu-button" [matMenuTriggerFor]="sideMenu">
      <mat-icon>menu</mat-icon>
    </button>

    <mat-menu #sideMenu="matMenu" class="side-menu">
      <a mat-menu-item routerLink="/dashboard" routerLinkActive="active" >Dashboard</a>
      <a mat-menu-item routerLink="/customers" routerLinkActive="active">Customers</a>
      <a mat-menu-item routerLink="/appointments" routerLinkActive="active">Appointments</a>
      <mat-divider></mat-divider>
      <a mat-menu-item routerLink="/profile" routerLinkActive="active">
        <mat-icon>article</mat-icon>
        <span>Profile</span>
      </a>
      <a mat-menu-item routerLink="/setting" routerLinkActive="active">
        <mat-icon>settings</mat-icon>
        <span>Settings</span>
      </a>
      <mat-divider></mat-divider>
      <button mat-menu-item (click)="signOut()">
        <mat-icon>exit_to_app</mat-icon>
        <span>Log out</span>
      </button>
    </mat-menu>
  </div>

</mat-toolbar>