import { HttpClient, HttpErrorResponse, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApiUrls } from '../config/api.endpoints';
import { Page } from '../models/page';
import { PaymentMethodModel } from '../models/payment.method';
import { QueryParams, createHttpParams } from '../models/query-params';
import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root'
})
export class PaymentMethodService {
  constructor(
    private http: HttpClient,
    private authService: AuthService,
  ) { }

  getPaymentMethod(
    paymentMethodId:number, onSuccess?:any, onError?:any
  ){
    const httpOptions = {
      headers: new HttpHeaders({
        'x-auth-token': this.authService.token
      }),
    };

    this.http.get<Page<PaymentMethodModel>>(ApiUrls.PAYMENT_METHODS+'/'+paymentMethodId, httpOptions)
      .subscribe((data)=>{
        if(onSuccess)
          onSuccess(data);
      }, (error:HttpErrorResponse)=>{
        if(onError)
          onError(error.status);
      });
  }

  getPaymentMethodList(
    queryParams?: QueryParams,
    onSuccess?:any, onError?:any
  ){
    
    const httpOptions = {
      params: createHttpParams(queryParams),
      headers: new HttpHeaders({
        'x-auth-token': this.authService.token
      }),
    };

    this.http.get<Page<PaymentMethodModel>>(ApiUrls.PAYMENT_METHODS, httpOptions)
      .subscribe((data)=>{
        if(onSuccess)
          onSuccess(data);
      }, (error:HttpErrorResponse)=>{
        if(onError)
          onError(error);
      });
  }

  deletePaymentMethod(
    paymentMethodId:number, 
    onSuccess?:any, onError?:any){
    const httpOptions = {
      headers: new HttpHeaders({
        'x-auth-token': this.authService.token
      }),
    };

    this.http.delete<void>(ApiUrls.PAYMENT_METHODS+'/'+paymentMethodId, httpOptions)
      .subscribe(()=>{
        if(onSuccess)
          onSuccess();
      }, (error:HttpErrorResponse)=>{
        if(onError)
          onError(error);
      });
  }

  undeletePaymentMethod(
    paymentMethodId:number, 
    onSuccess?:any, onError?:any){
    const httpOptions = {
      headers: new HttpHeaders({
        'x-auth-token': this.authService.token
      }),
    };

    this.http.get<void>(ApiUrls.PAYMENT_METHODS+'/recycle/'+paymentMethodId, httpOptions)
      .subscribe(()=>{
        if(onSuccess)
          onSuccess();
      }, (error:HttpErrorResponse)=>{
        if(onError)
          onError(error);
      });
  }

  savePaymentMethod(
    paymentMethod:PaymentMethodModel,
    onSuccess?:any, onError?:any
  ){
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'x-auth-token': this.authService.token
      }),
    };

    this.http.post<PaymentMethodModel>(ApiUrls.PAYMENT_METHODS, paymentMethod, httpOptions)
      .subscribe((data)=>{
        if(onSuccess)
          onSuccess(data);
      }, (error:HttpErrorResponse)=>{
        if(onError)
          onError(error.status);
      });
  }
}
