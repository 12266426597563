<h2 mat-dialog-title>Search Customer</h2>

<mat-dialog-content [formGroup]="form">
  <mat-form-field class="w-100">
    <mat-label>Filter</mat-label>
    <input matInput
            type="text"
            placeholder="Filter to apply"
            required
           formControlName="keyword">
</mat-form-field>
</mat-dialog-content>
<mat-divider></mat-divider>
<mat-dialog-actions align="end">
  <button mat-button (click)="clear()">Clear</button> 
  <button mat-button color="accent" (click)="search()">Search</button>
</mat-dialog-actions>