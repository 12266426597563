import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApiUrls } from '../config/api.endpoints';
import { AuthenticateToken } from '../models/authenticate.token';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  expireDate: Date|null=null;
  token: string ='';
  static TOKEN_KEY='FLN_TOKEN';
  static EXPIRE_KEY='FLN_EXPIRE_DATE';

  constructor(
    private http: HttpClient
  ) {
    this.readToken();
  }

  authenticate(username: string, password: string, onSuccess?:any, onError?:any): void{
    const body = {
      username,
      password
    };
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      }),
    };
    this.http.post<AuthenticateToken>(ApiUrls.AUTH, body, httpOptions)
      .subscribe((token)=>{
        this.token=token.token;
        this.expireDate= new Date(token.expireDate);
        this.saveToken();
        if(onSuccess)
          onSuccess();
      }, (error:HttpErrorResponse)=>{        
        if(onError)
          onError(error);
      });
  }

  public readToken(): void {
    const token = localStorage.getItem(AuthService.TOKEN_KEY);
    const expireDate = localStorage.getItem(AuthService.EXPIRE_KEY);
    
    if (token !== null) {
      this.token = token;
    }
    else{
      this.token='';
    }
    
    if (expireDate !== null) {
      this.expireDate= new Date(expireDate);
    }
    else{
      this.expireDate=null;
    }
  }

  public getToken(): string{
    return this.token;
  }

  public saveToken(): void {
    localStorage.setItem(AuthService.TOKEN_KEY, this.token);
    localStorage.setItem(AuthService.EXPIRE_KEY, this.expireDate?this.expireDate.toISOString():'');
  }

  public deleteToken(): void {
    localStorage.removeItem(AuthService.TOKEN_KEY);
    localStorage.removeItem(AuthService.EXPIRE_KEY);
    this.token='';
    this.expireDate=null;
  }

  /**
   * 
   * @returns boolean:<br> retrun true if token is expired.<br> fasle if token is valid.
   */
  public isTokenExpired(): boolean{
    if (this.token === '' || this.expireDate === null) {
      return true;
    }

    return this.expireDate < new Date();
  }

  public isLogin(): boolean{
    return !this.isTokenExpired();
  }

}
