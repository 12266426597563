<div ngClass.lt-sm="mobile-container">
  <app-header #header class="app-header"
    [company]="getCompanyName()"
    [username]="getUserName()"
    (changeCompany)="openSelectCompanyDlg()"
    (signout)="signOut()" ></app-header>
  <div class="app-body">
    <div class="app-contents">
      <router-outlet></router-outlet>
    </div>
  </div>
</div>

