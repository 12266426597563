import { Component, Inject, OnInit } from '@angular/core';
import { Toast, ToastrService, ToastPackage, IndividualConfig} from 'ngx-toastr';

@Component({
  selector: 'custom-toast-commponent',
  templateUrl: './custom-toast.component.html',
  styleUrls: ['./custom-toast.component.scss']
})

export class CustomToastComponent extends Toast {  
  options: any = {};

  // constructor is only necessary when not using AoT
  constructor(
    protected toastrService: ToastrService,
    public toastPackage: ToastPackage,
  ) {
    super(toastrService, toastPackage);
  }

  action() {    
    this.toastPackage.triggerAction();
    this.toastrService.clear();
    return false;
  }

}
