<h2 mat-dialog-title>{{title}}</h2>
<form [formGroup]="form" (ngSubmit)="save()">
  <mat-dialog-content>
    <mat-form-field class="w-100">
      <mat-label>Service</mat-label>
      <mat-select
            placeholder="Select Service"
            required
          formControlName="service">          
          <mat-option *ngFor="let s of services" [value]="s.serviceId">{{s.label}}</mat-option>
      </mat-select>
      <mat-error *ngIf="serviceInput.invalid">{{serviceInputError}}</mat-error>
    </mat-form-field>
    <mat-form-field class="w-100">
      <mat-label>Subject</mat-label>
      <input matInput
              type="text"
              required
              placeholder="Subject..."
            formControlName="subject">
      <mat-error *ngIf="subjectInput.invalid">{{subjectInputError}}</mat-error>
    </mat-form-field>
    <mat-form-field class="w-100">
        <mat-label>Date</mat-label>
        <input matInput formControlName="date" required [matDatepicker]="picker" (dateInput)="inputDate()" (dateChange)="changeProfessionalAndDate()">
        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
        <mat-datepicker #picker></mat-datepicker>
        <mat-error *ngIf="dateInput.invalid">{{dateInputError}}</mat-error>
    </mat-form-field>
    <div fxLayout="row" fxLayoutGap="5px">
      <mat-form-field fxFill fxFlex="50%">
          <mat-label>From</mat-label>
          <mat-select
                  placeholder="Time From"
                  required
                  formControlName="timeFrom">
            <mat-option *ngFor="let t of serviceTimeList" [value]="t">{{t}}</mat-option>
          </mat-select>
          <mat-error *ngIf="timeFromInput.invalid">{{timeFromInputError}}</mat-error>
      </mat-form-field>
      <mat-form-field fxFill fxFlex="50%">
          <mat-label>To</mat-label>
          <mat-select
                  placeholder="Time To"
                  required
                formControlName="timeTo">
                <mat-option *ngFor="let t of serviceTimeList" [value]="t">{{t}}</mat-option>
          </mat-select>
          <mat-error *ngIf="timeToInput.invalid">{{timeToInputError}}</mat-error>
      </mat-form-field>
    </div>
    <mat-form-field class="w-100">
      <mat-label>Doctor</mat-label>
      <mat-select
              placeholder="Select Doctor"
              required
              (selectionChange)="changeProfessionalAndDate()"
            formControlName="professional">              
            <mat-option *ngFor="let p of professionals" [value]="p.professionalId">{{p.firstname}} {{p.lastname}}</mat-option>
      </mat-select>
      <mat-error *ngIf="professionalInput.invalid">{{professionalInputError}}</mat-error>
    </mat-form-field>
    
    <div class="table-container">
      <h4>Scheduled Appointments</h4>
      <div class="table-wrapper">
        <div class="spinner-container" *ngIf="loading">
          <mat-spinner></mat-spinner>
        </div>
        <table mat-table [dataSource]="scheduledEvents"  *ngIf="!loading" class="w-100">
      
          <ng-container matColumnDef="time">
            <th mat-header-cell *matHeaderCellDef> Time </th>
            <td mat-cell *matCellDef="let row"> {{row.startTime| date:'HH:mm':'MST'}}-{{row.endTime| date:'HH:mm':'MST'}} </td>
          </ng-container>
      
          <ng-container matColumnDef="customer">
            <th mat-header-cell *matHeaderCellDef> Customer </th>
            <td mat-cell *matCellDef="let row"> {{row.customerName}} </td>
          </ng-container>
      
      
          <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
      
          <tr class="mat-row" *matNoDataRow>
            <td class="mat-cell" colspan="4">No Appointment</td>
          </tr>
        </table>
      </div>
    </div>
  </mat-dialog-content>
  <mat-divider></mat-divider>

  <mat-dialog-actions align="end">
    <button type="button" mat-raised-button mat-dialog-close color="warn">Close</button> 
    <button type="submit" mat-raised-button color="accent">Save</button>
  </mat-dialog-actions>
</form>