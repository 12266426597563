import { Component, Inject, OnInit } from '@angular/core';
import { FormGroup, FormControl, FormBuilder, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { ProfessionalModel } from 'src/app/models/professional';
import { ProfessionalService } from 'src/app/services/professional.service';
import { ToastService } from 'src/app/services/toast.service';
import { Constants } from 'src/app/config/constant';


@Component({
  selector: 'app-edit-professional-dlg',
  templateUrl: './edit-professional-dlg.component.html',
  styleUrls: ['./edit-professional-dlg.component.scss']
})
export class EditProfessionalDlgComponent implements OnInit {
  form: FormGroup;
  title:string;
  bCreateMode:boolean;

  constructor(
    private router: Router,
    private proService: ProfessionalService, 
    private fb: FormBuilder,
    private toastService: ToastService,
    private dialogRef: MatDialogRef<EditProfessionalDlgComponent>,
    @Inject(MAT_DIALOG_DATA) data:ProfessionalModel
  ) { 
    this.form = this.fb.group({
      firstname: [data.firstname, [Validators.required]],
      lastname: [data.lastname, [Validators.required]],
      phoneNo: [data.phoneNo],
      email: [data.email, [Validators.required, Validators.email]],
      language: [data.language],
      status: [data.status],
      professionalId: [data.professionalId],
      clientId: [data.clientId],
      createdDate: [data.createdDate],
      modifiedDate: [data.modifiedDate],
      createdBy: [data.createdBy],
      modifiedBy: [data.modifiedBy],
    });
    if(data.professionalId===0){
      this.title='Add Professional';
      this.bCreateMode=true;
    }
    else{
      this.bCreateMode=false;
      this.title='Edit Professional';
    }
  }

  ngOnInit(): void {
  }

  save(){
    if (this.form.valid) {
      this.proService.saveProfessional(
        this.form.value,
        this.onSave,
        this.onNotSave
      );
    }
  }

  close() {
    this.dialogRef.close();
  }

  onSave=(pro:ProfessionalModel)=>{
    this.toastService.showSuccess(Constants.message.successSaved);
    this.dialogRef.close(pro);
  }

  onNotSave=(error: any) => {
    this.toastService.showResponseError(error);
    if(error.status == 401){
      this.router.navigate(['/auth/sign-in']);
    }
  }

  get emailInput(): any {return this.form.get('email'); }
  get emailInputError(): string | void{
    if (this.emailInput.hasError('email')) { return Constants.message.invalidEmail; }
    if (this.emailInput.hasError('required')) { return Constants.message.requiredField; }
  }

  get firstnameInput(): any {return this.form.get('firstname'); }
  get firstnameInputError(): string | void {    
    if (this.firstnameInput.hasError('required')) { return Constants.message.requiredField; }
  }

  get lastnameInput(): any {return this.form.get('lastname'); }
  get lastnameInputError(): string | void {    
    if (this.lastnameInput.hasError('required')) { return Constants.message.requiredField; }
  }

}
