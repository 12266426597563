<h2 mat-dialog-title>{{title}}</h2>
<form [formGroup]="form" (ngSubmit)="save()">
    <mat-dialog-content>
        <mat-form-field appearance="standard" class="w-100">
            <mat-label>Office Name</mat-label>
            <input matInput
                    type="text"
                    placeholder="Office Name..."
                    required
                formControlName="name">
            <mat-error *ngIf="nameInput.invalid">{{nameInputError}}</mat-error>
        </mat-form-field>
        <input type="hidden" formControlName="clientId" />
        <input type="hidden" formControlName="companyId" />
        <input type="hidden" formControlName="companyName" />
        <input type="hidden" formControlName="officeId" />
        <input type="hidden" formControlName="createdDate" />
        <input type="hidden" formControlName="modifiedDate" />
        <input type="hidden" formControlName="createdBy" />
        <input type="hidden" formControlName="modifiedBy" />
    </mat-dialog-content>
    <mat-divider></mat-divider>
    <mat-dialog-actions align="end">
        <button type="button" mat-raised-button mat-dialog-close color="warn">Close</button> 
        <button type="submit" mat-raised-button color="accent">Save</button>
    </mat-dialog-actions>
</form>