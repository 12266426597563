<h2 mat-dialog-title>{{title}}</h2>
<form [formGroup]="form" (ngSubmit)="save()">
    <mat-dialog-content>
        <mat-form-field class="w-100">
            <mat-label>Tax Code</mat-label>
            <input matInput
                    type="text"
                    placeholder="TAX CODE"
                    required
                formControlName="taxCode">
            <mat-error *ngIf="taxCodeInput.invalid">{{taxCodeInputError}}</mat-error>
        </mat-form-field>
        <mat-form-field class="w-100">
            <mat-label>Tax Label</mat-label>
            <input matInput
                    type="text"
                    placeholder="Label"
                    required
                formControlName="taxLabel">
            <mat-error *ngIf="taxLabelInput.invalid">{{taxLabelInputError}}</mat-error>
        </mat-form-field>
        <mat-form-field class="w-100">
            <mat-label>Tax Calculation</mat-label>
            <mat-select
                    placeholder="Select Calculation Mode"
                    required
                formControlName="taxCalculation">
                <mat-option value="P">Percentage</mat-option>
                <mat-option value="A">Absolute</mat-option>
            </mat-select>
            <mat-error *ngIf="taxCalculationInput.invalid">{{taxCalculationInputError}}</mat-error>
        </mat-form-field>
        <mat-form-field class="w-100">
            <mat-label>Tax Rate</mat-label>
            <input matInput
                    type="number"
                    placeholder="Rate"
                formControlName="taxRate">
        </mat-form-field>
        <mat-form-field class="w-100">
            <mat-label>Comment</mat-label>
            <input matInput
                    type="text"
                    placeholder="Description..."
                formControlName="comment">
        </mat-form-field>
        <input type="hidden" formControlName="clientId" />
        <input type="hidden" formControlName="taxCodeId" />
        <input type="hidden" formControlName="companyId" />
        <input type="hidden" formControlName="createdDate" />
        <input type="hidden" formControlName="modifiedDate" />
        <input type="hidden" formControlName="createdBy" />
        <input type="hidden" formControlName="modifiedBy" />
    </mat-dialog-content>
    <mat-divider></mat-divider>
    <mat-dialog-actions align="end">
        <button type="button" mat-raised-button mat-dialog-close color="warn">Close</button> 
        <button type="submit" mat-raised-button color="accent">Save</button>
    </mat-dialog-actions>
</form>