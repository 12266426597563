import { HttpClient, HttpErrorResponse, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApiUrls } from '../config/api.endpoints';
import { CustomerModel } from '../models/customer';
import { Page } from '../models/page';
import { QueryParams, createHttpParams } from '../models/query-params';
import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root'
})
export class CustomerService {
  constructor(
    private http: HttpClient,
    private authService: AuthService,
  ) { }

  getCustomer(
    customerId:number, onSuccess?:any, onError?:any
  ){
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'x-auth-token': this.authService.token
      }),
    };

    this.http.get<Page<CustomerModel>>(ApiUrls.CUSTOMERS+'/'+customerId, httpOptions)
      .subscribe((data)=>{
        if(onSuccess)
          onSuccess(data);
      }, (error:HttpErrorResponse)=>{
        if(onError)
          onError(error.status);
      });
  }

  getCustomers(
    queryParams?: QueryParams,
    onSuccess?:any, onError?:any
  ){
    
    const httpOptions = {
      params: createHttpParams(queryParams),
      headers: new HttpHeaders({
        'x-auth-token': this.authService.token
      }),
    };

    this.http.get<Page<CustomerModel>>(ApiUrls.CUSTOMERS, httpOptions)
      .subscribe((data)=>{
        if(onSuccess)
          onSuccess(data);
      }, (error:HttpErrorResponse)=>{
        if(onError)
          onError(error.status, error);
      });
  }

  deleteCustomer(
    customerId:number, 
    onSuccess?:any, onError?:any){
    const httpOptions = {
      headers: new HttpHeaders({
        'x-auth-token': this.authService.token
      }),
    };

    this.http.delete<void>(ApiUrls.CUSTOMERS+'/'+customerId, httpOptions)
      .subscribe(()=>{
        if(onSuccess)
          onSuccess();
      }, (error:HttpErrorResponse)=>{
        if(onError)
          onError(error);
      });
  }

  undeleteCustomer(
    customerId:number, 
    onSuccess?:any, onError?:any){
    const httpOptions = {
      headers: new HttpHeaders({
        'x-auth-token': this.authService.token
      }),
    };

    this.http.get<void>(ApiUrls.CUSTOMERS+'/recycle/'+customerId, httpOptions)
      .subscribe(()=>{
        if(onSuccess)
          onSuccess();
      }, (error:HttpErrorResponse)=>{
        if(onError)
          onError(error);
      });
  }

  saveCustomer(
    customer:CustomerModel,
    onSuccess?:any, onError?:any
  ){
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'x-auth-token': this.authService.token
      }),
    };

    this.http.post<CustomerModel>(ApiUrls.CUSTOMERS, customer, httpOptions)
      .subscribe((data)=>{
        if(onSuccess)
          onSuccess(data);
      }, (error:HttpErrorResponse)=>{
        if(onError)
          onError(error);
      });
  }
}
