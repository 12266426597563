import { Component, Inject, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { ServiceModel } from 'src/app/models/service';
import { ServiceService } from 'src/app/services/service.service';
import { ToastService } from 'src/app/services/toast.service';
import { Constants } from 'src/app/config/constant';

@Component({
  selector: 'app-edit-service-dlg',
  templateUrl: './edit-service-dlg.component.html',
  styleUrls: ['./edit-service-dlg.component.scss'],
})
export class EditServiceDlgComponent implements OnInit {
  form: FormGroup;
  title: string;
  bCreateMode: boolean;

  constructor(
    private router: Router,
    private svcService: ServiceService,
    private dialogRef: MatDialogRef<EditServiceDlgComponent>,
    private fb: FormBuilder,
    private toastService: ToastService,
    @Inject(MAT_DIALOG_DATA) data: ServiceModel
  ) {
    this.form = this.fb.group({
      category: [data.category, [Validators.required]],
      clientId: [data.clientId],
      code: [data.code, [Validators.required]],
      companyId: [data.companyId],
      description: [data.description],
      label: [data.label, [Validators.required]],
      price: [data.price, [Validators.required, Validators.min(1)]],
      serviceId: [data.serviceId],
      status: [data.status, [Validators.required]],
      taxCode: [data.taxCode],
      createdDate: [data.createdDate],
      modifiedDate: [data.modifiedDate],
      createdBy: [data.createdBy],
      modifiedBy: [data.modifiedBy],
    });
    if (data.serviceId === 0) {
      this.title = 'Add Service';
      this.bCreateMode = true;
    } else {
      this.bCreateMode = false;
      this.title = 'Edit Service';
    }
  }

  ngOnInit(): void {}

  save() {
    if (this.form.valid) {
      // the data is correct here (price editted correctly) and in the network tab on dev tools
      // the correct data is posting
      console.log('edit_service_dlg', this.form.value);
      this.svcService.saveService(this.form.value, this.onSave, this.onNotSave);
    }
  }

  close() {
    this.dialogRef.close();
  }

  onSave = (pro: ServiceModel) => {
    this.toastService.showSuccess(Constants.message.successSaved);
    this.dialogRef.close(pro);
  };

  onNotSave = (error: any) => {
    this.toastService.showResponseError(error);
    if (error.status == 401) {
      this.router.navigate(['/auth/sign-in']);
    }
  };

  get categoryInput(): any {
    return this.form.get('category');
  }
  get categoryInputError(): string | void {
    if (this.categoryInput.hasError('required')) {
      return Constants.message.requiredField;
    }
  }
  get codeInput(): any {
    return this.form.get('code');
  }
  get codeInputError(): string | void {
    if (this.codeInput.hasError('required')) {
      return Constants.message.requiredField;
    }
  }
  get labelInput(): any {
    return this.form.get('label');
  }
  get labelInputError(): string | void {
    if (this.labelInput.hasError('required')) {
      return Constants.message.requiredField;
    }
  }
  get priceInput(): any {
    return this.form.get('price');
  }
  get priceInputError(): string | void {
    if (this.priceInput.hasError('required')) {
      return Constants.message.requiredField;
    }
    if (this.priceInput.hasError('min')) {
      return Constants.message.invalidMinValue1;
    }
  }
  get statusInput(): any {
    return this.form.get('status');
  }
  get statusInputError(): string | void {
    if (this.statusInput.hasError('required')) {
      return Constants.message.requiredField;
    }
  }
}
