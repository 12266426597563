<h2 mat-dialog-title>Customer Appointment</h2>

<mat-dialog-content>
  <div fxFill fxLayout="column">
    <div class="info-item">
      <span class="label">Service:</span>
      <span class="value">{{event.eventServices[0].serviceLabel}}</span>
    </div>
    <div class="info-item">
      <span class="label">Customer:</span>
      <span class="value">{{customer.firstname}} {{customer.lastname}}</span>
    </div>
    <div class="info-item">
      <span class="label">Date:</span>
      <span class="value">{{event.startTime| date:'E, LLL d'}}</span>
    </div>
    <div class="info-item">
      <span class="label">Time:</span>
      <span class="value">{{event.startTime| date:'HH:mm':'MST'}}-{{event.endTime| date:'HH:mm':'MST'}}</span>
    </div>
    <div class="info-item">
      <span class="label">Professional:</span>
      <span class="value">{{event.professionalName}}</span>
    </div>
  </div>
</mat-dialog-content>

<mat-dialog-actions align="end">
    <button mat-button color="accent" mat-dialog-close>OK</button>
</mat-dialog-actions>