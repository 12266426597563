import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  @Input() company: string = "MyCompany";
  @Input() username:string = "";
  @Output() changeCompany = new EventEmitter<void>();
  @Output() signout = new EventEmitter<void>();

  constructor() { }

  ngOnInit(): void {
  }

  fireChangeCompany(){
    this.changeCompany.emit();
  }

  signOut(){
    this.signout.emit();
  }
}
