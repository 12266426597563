import { Component, Inject, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { NoteModel } from 'src/app/models/note';
import { NoteDlgModel } from 'src/app/models/note.dlg';
import { NoteService } from 'src/app/services/note.service';
import { ToastService } from 'src/app/services/toast.service';
import { Constants } from 'src/app/config/constant';


@Component({
  selector: 'app-edit-note-dlg',
  templateUrl: './edit-note-dlg.component.html',
  styleUrls: ['./edit-note-dlg.component.scss']
})
export class EditNoteDlgComponent implements OnInit {
  form: FormGroup;
  title:string;
  refTable:string;
  userId:number;
  bCreateMode:boolean;

  constructor(
    private router: Router,
    private noteService: NoteService, 
    private fb: FormBuilder,
    private toastService: ToastService,
    private dialogRef: MatDialogRef<EditNoteDlgComponent>,
    @Inject(MAT_DIALOG_DATA) data:NoteDlgModel,
  ) {
    this.form = this.fb.group({
      category: [data.category],
      clientId: [data.clientId],
      note: [data.note, [Validators.required, Validators.minLength(Constants.note.minLength), Validators.maxLength(Constants.note.maxLength)]],
      noteId: [data.noteId],
      status: [data.status],
      referenceId: [data.referenceId],
      createdDate: [data.createdDate],
      modifiedDate: [data.modifiedDate],
      createdBy: [data.createdBy],
      modifiedBy: [data.userId]
    });
    if(data.noteId===0){
      this.title='Add Note';
      this.bCreateMode=true;
    }
    else{
      this.bCreateMode=false;
      this.title='Edit Note';
    }
    this.refTable=data.refTable;
    this.userId=data.userId;
  }

  ngOnInit(): void {
  }

  save(){    
    if (this.form.valid) {
      const note:NoteModel=this.form.value as NoteModel;
      note.category=this.refTable;
      this.noteService.saveNote(
        this.refTable,
        this.userId,
        note,
        this.onSave,
        this.onNotSave
      );
    }
  }

  close() {
    this.dialogRef.close();
  }

  onSave=(address:NoteModel)=>{
    this.toastService.showSuccess(Constants.message.successSaved);
    this.dialogRef.close(address);
  }

  onNotSave=(error: any) => {
    this.toastService.showResponseError(error);
    if(error.status == 401){
      this.router.navigate(['/auth/sign-in']);
    }
  }

  get noteInput(): any {return this.form.get('note'); }
  get noteInputError(): string | void {           
    if (this.noteInput.hasError('required')) { return Constants.message.requiredField; }    
    if (this.noteInput.hasError('minlength')) { return Constants.message.invalidNoteMinLength.replace('?', Constants.note.minLength.toString()); }
    if (this.noteInput.hasError('maxlength')) { return Constants.message.invalidNoteMaxLength.replace('?', Constants.note.maxLength.toString()); }
  }
}
