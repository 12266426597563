<h2 mat-dialog-title>{{title}}</h2>
<form [formGroup]="form" (ngSubmit)="save()">
  <mat-dialog-content [formGroup]="form">
    <p *ngIf="error" class="error">{{error}}</p>
    <div fxLayout="row" fxLayout.lt-sm="column" fxLayoutGap="10px" fxLayoutGap.lt-sm="0">
      <div fxFlex="50%">
        <mat-form-field class="w-100">
          <mat-label>Email</mat-label>
          <input matInput
                  type="text"
                  required
                  readonly
                formControlName="email">
          <mat-error *ngIf="emailInput.invalid">{{emailInputError}}</mat-error>
        </mat-form-field>
      </div>
      <div fxFlex="50%">
        <mat-form-field class="w-100">
          <mat-label>Invoice Date</mat-label>
          <input matInput formControlName="invoiceDate" required [matDatepicker]="picker1">
          <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
          <mat-datepicker #picker1></mat-datepicker>
          <mat-error *ngIf="invoiceDateInput.invalid">{{invoiceDateInputError}}</mat-error>
        </mat-form-field>
        <mat-form-field class="w-100">
          <mat-label>Due Date</mat-label>
          <input matInput formControlName="invoiceDueDate" required [matDatepicker]="picker2">
          <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
          <mat-datepicker #picker2></mat-datepicker>
          <mat-error *ngIf="invoiceDueDateInput.invalid">{{invoiceDueDateInputError}}</mat-error>
        </mat-form-field>
      </div>
    </div>
    
    <div class="invoice-lines-container">
      <div class="title-bar">
        <h3>Invoice Details</h3>
        <span class="example-spacer"></span>
        <div>
          <button mat-raised-button color="accent" (click)="AddInvoiceLine()">+<span fxHide.xs> New Row </span></button>
        </div>
      </div>
      <div class="invoice-lines-wrapper">
        <table class="mat-table cdk-table w-100" ngClass="mobile" ngClass.gt-xs="mat-elevation-z2">
          <thead fxHide.xs>
            <tr class="mat-header-row cdk-header-row ng-star-inserted">
              <th class="mat-header-cell cdk-header-cell cdk-column-date mat-column-date ng-star-inserted mat-table-sticky mat-table-sticky-border-elem-top description-col">Description</th>
              <th class="mat-header-cell cdk-header-cell cdk-column-date mat-column-date ng-star-inserted mat-table-sticky mat-table-sticky-border-elem-top quality-col">Qty</th>
              <th class="mat-header-cell cdk-header-cell cdk-column-date mat-column-date ng-star-inserted mat-table-sticky mat-table-sticky-border-elem-top rate-col">Rate</th>
              <th class="mat-header-cell cdk-header-cell cdk-column-date mat-column-date ng-star-inserted mat-table-sticky mat-table-sticky-border-elem-top tax-col">Tax</th>
              <th class="mat-header-cell cdk-header-cell cdk-column-date mat-column-date ng-star-inserted mat-table-sticky mat-table-sticky-border-elem-top amount-col">Amount</th>
              <th class="mat-header-cell cdk-header-cell cdk-column-date mat-column-date ng-star-inserted mat-table-sticky mat-table-sticky-border-elem-top action-col"></th>
            </tr>
          </thead>
          <tbody formArrayName="invoiceLines">
            <tr class="mat-row cdk-row ng-star-inserted" *ngFor="let row of getInvoiceLineFormArray().controls; let i = index" [formGroupName]="i">
              <td class="mat-cell cdk-cell cdk-column-date mat-column-date ng-star-inserted description-col">
                  <label fxHide.gt-xs>Description</label>
                  <input type="text" formControlName="description" required>
              </td>
              <td class="mat-cell cdk-cell cdk-column-date mat-column-date ng-star-inserted quality-col">
                <label fxHide.gt-xs>Quantity</label>
                <input type="number" formControlName="quantity" required min="1">
              </td>
              <td class="mat-cell cdk-cell cdk-column-date mat-column-date ng-star-inserted rate-col">
                <label fxHide.gt-xs>Rate</label>
                <input type="number" formControlName="rate" required min="0">
              </td>
              <td class="mat-cell cdk-cell cdk-column-date mat-column-date ng-star-inserted tax-col">
                <label fxHide.gt-xs>Tax</label>
                <select formControlName="taxCode" required>
                  <option *ngFor="let t of taxes" [value]="t.taxCode">{{t.taxLabel}}</option>
                </select>
              </td>
              <td class="mat-cell cdk-cell cdk-column-date mat-column-date ng-star-inserted amount-col">
                <label fxHide.gt-xs>Amount</label>
                <span class="form-field">${{row.value.quantity*row.value.rate|number : '1.2-2'}} {{invoiceData.currencyCode}}</span>
              </td>
              <td class="mat-cell cdk-cell cdk-column-date mat-column-date ng-star-inserted action-col">
                <button mat-icon-button color="warn" (click)="removeRow(i)">
                  <mat-icon>close</mat-icon>
                </button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="summary">
        <div class="info-item">
          <span class="label">Total Tax:</span>
          <span class="value">${{calculateTotalTax()|number : '1.2-2'}} {{invoiceData.currencyCode}}</span>
        </div>
        <div class="info-item">
          <span class="label">Total Amount:</span>
          <span class="value">${{calculateTotalAmount()|number : '1.2-2'}} {{invoiceData.currencyCode}}</span>
        </div>
      </div>
    </div>
  </mat-dialog-content>
  <mat-divider></mat-divider>

  <mat-dialog-actions align="end">
    <button type="button" mat-raised-button mat-dialog-close color="warn">Close</button> 
    <button type="submit" mat-raised-button color="accent">Save</button>
  </mat-dialog-actions>
</form>