import {
  HttpClient,
  HttpErrorResponse,
  HttpHeaders,
  HttpParams,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApiUrls } from '../config/api.endpoints';
import { AddressModel } from '../models/address';
import { Page } from '../models/page';
import { QueryParams, createHttpParams } from '../models/query-params';
import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root',
})
export class AddressService {
  constructor(private http: HttpClient, private authService: AuthService) {}

  getAddressList(
    refTable: string,
    refId: number,
    queryParams?: QueryParams,
    onSuccess?: any,
    onError?: any
  ) {
    const httpOptions = {
      params: createHttpParams(queryParams),
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'x-auth-token': this.authService.token,
      }),
    };

    this.http
      .get<Page<AddressModel>>(
        ApiUrls.ADDRESS + '/' + refTable + '/' + refId,
        httpOptions
      )
      .subscribe(
        (data) => {
          if (onSuccess) onSuccess(data);
        },
        (error: HttpErrorResponse) => {
          if (onError) onError(error);
        }
      );
  }

  createOrUpdateAddress(
    refTable: string,
    refId: number,
    address: AddressModel,
    onSuccess?: any,
    onError?: any
  ) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'x-auth-token': this.authService.token,
      }),
    };

    this.http
      .post<AddressModel>(
        ApiUrls.ADDRESS + '/' + refTable + '/' + refId,
        address,
        httpOptions
      )
      .subscribe(
        (data) => {
          console.log(data);

          if (onSuccess) onSuccess(data);
        },
        (error: HttpErrorResponse) => {
          if (onError) onError(error);
        }
      );
  }

  deleteAddress(addressId: number, onSuccess?: any, onError?: any) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'x-auth-token': this.authService.token,
      }),
    };

    this.http
      .delete<void>(ApiUrls.ADDRESS + '/' + addressId, httpOptions)
      .subscribe(
        () => {
          if (onSuccess) onSuccess();
        },
        (error: HttpErrorResponse) => {
          if (onError) onError(error);
        }
      );
  }

  undeleteAddress(addressId: number, onSuccess?: any, onError?: any) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'x-auth-token': this.authService.token,
      }),
    };

    this.http
      .get<void>(ApiUrls.ADDRESS + '/recycle/' + addressId, httpOptions)
      .subscribe(
        () => {
          if (onSuccess) onSuccess();
        },
        (error: HttpErrorResponse) => {
          if (onError) onError(error);
        }
      );
  }
}
