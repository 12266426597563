import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AddressDlgModel } from 'src/app/models/address.dlg';
import { CustomerModel } from 'src/app/models/customer';
import { EventModel } from 'src/app/models/event';

@Component({
  selector: 'app-show-event-dlg',
  templateUrl: './show-event-dlg.component.html',
  styleUrls: ['./show-event-dlg.component.scss']
})
export class ShowEventDlgComponent implements OnInit {
  customer:CustomerModel;
  event:EventModel;

  constructor(
    private dialogRef: MatDialogRef<ShowEventDlgComponent>,
    @Inject(MAT_DIALOG_DATA) data:any
  ) {
    this.customer = data.customer;
    this.event = data.event;
  }

  ngOnInit(): void {
  }

}
