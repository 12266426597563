import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-confirm-dlg',
  templateUrl: './confirm-dlg.component.html',
  styleUrls: ['./confirm-dlg.component.scss']
})
export class ConfirmDlgComponent implements OnInit {
  title: string;
  subject: string|undefined|null;
  text:string|undefined|null;
  onsuccess:any;
  oncancel:any;

  constructor(
    private dialogRef: MatDialogRef<ConfirmDlgComponent>,
    @Inject(MAT_DIALOG_DATA) data:any
  ) {
    this.title=data.title;
    this.subject=data.subject;
    this.text=data.text;
    this.onsuccess=data.onsuccess;
    this.oncancel=data.oncancel;
  }

  ngOnInit(): void {
  }

  close() {
    if(this.oncancel)
      this.oncancel();
    this.dialogRef.close();
  }

  confirm(){
    if(this.onsuccess)
      this.onsuccess();
    this.dialogRef.close();
  }
}
